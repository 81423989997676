import { Alert, Box, Snackbar } from "@mui/material";
import { Button } from "@mui/material";
import { bnb, eth, usdt } from "./Images";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: props.colortext,
          background: "#00ffe4",
          fontSize: "18px",
          textTransform: "capitalize",
          fontFamily: "Poppins",
          borderRadius: "50px",
          width: props.width,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background: "#00ffe4",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}
export function StyledCurrencyButton({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: props.color,
          background: "#F1F4F6",
          fontSize: "16px",
          border: props.border,
          textTransform: "capitalize",
          borderRadius: "12px",
          width: props.width,
          height: "45px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: props.cursor,
        }}
      >
        <img
          width="25px"
          src={children === "ETH" ? eth : children === "BNB" ? bnb : usdt}
          alt=""
          style={{ marginRight: "10px" }}
        />{" "}
        {children}
      </Box>
    </>
  );
}

export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#ffffff",
          fontSize: "15px",
          fontWeight: "500",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
