import React from 'react'

function Backers() {
  return (
    <div className='backers'>
       <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto my-auto">
                
                <div className='d-flex justify-content-center'>
                <h1 style={{color:'#23F7DD'}}>Future Collaborators</h1>
                </div>
                <div className="row text-center mt-5">
                   
                    <h4>
                Deta Finance envisions potential partnerships with industry-leading exchanges. Our goal is to work towards listing Deta on high-end platforms, enhancing accessibility and expanding opportunities for our users. We are excited to pursue these collaborations to bring Deta to a wider audience within the crypto community</h4>

                {/* <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/1.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/2.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/3.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/5.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/6.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/7.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/8.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/9.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/10.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/11.png" width={'70%'}/></div></div>
                <div className="col-lg-3 mx-auto my-auto"><div className='image-wrap'><img src="/assets/images/backers/4.png" width={'30%'}/></div></div>
                */}
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Backers