import React, { useState } from "react";
import MobileHeader from "./MobileHeader";
// import FirstSlider from "../../Components/shared/FirstSlider";
import { BiSearchAlt2 } from 'react-icons/bi'
import { MdKeyboardArrowDown } from 'react-icons/md'
import PreSale from "../../Pages/PresalePage";
import one from '../../assets/video/one.mp4'
import { Link } from "react-router-dom";
import whitepaper from '../../assets/detawhitepaper.pdf'
import detaAudit from '../../assets/DetaAudit.pdf'

const Header = () => {
  const [toggle, setToggle] = useState(false)
  const scrollToChart = () => {
    document
      .getElementById("DetaPieChart")
      ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
  }
  return (
    <>
      <nav data-component="navbar" data-v-61fec360="" data-v-06913c84="" data-v-114d1840="" id="hide-on-desktop">
        <div className="row align-center expanded" data-v-61fec360="">
          <div className="navbar-main column xxlarge-30 xsmall-28" data-v-61fec360="">
            <span className="logotype" data-v-61fec360="" style={{ translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: '1' }}>
              <a target="_blank" href="https://deta.io/" aria-current="page" aria-label="Home button" className="nuxt-link-exact-active nuxt-link-active" data-v-61fec360="">
                <img src="/assets/logo/logo-light.png" alt="" srcSet="" />
              </a>
            </span>
            <span data-v-61fec360="" className="routes">
              <span data-v-61fec360="" className="main-btn" onClick={scrollToChart}>
                <a data-v-3238a8e3="" data-v-776b7856="" data-v-61fec360="" className="route-btn" data-component="navbar-link" tabIndex="0">
                  <span data-v-776b7856="" className="label">Token</span>

                </a>
              </span>
              <span data-v-61fec360="" className="main-btn" >
                <a data-v-3238a8e3="" href={detaAudit} data-v-776b7856="" data-v-61fec360="" className="route-btn" data-component="navbar-link" tabIndex="0">
                  <span data-v-776b7856="" className="label">Security</span>
 
 
                </a>
              </span>
              <span data-v-61fec360="" className="main-btn" >
                <a href=" https://detatokens-organization.gitbook.io/deta-finance-documentation/" target="_blank" data-v-3238a8e3="" data-v-776b7856="" data-v-61fec360="" className="route-btn" data-component="navbar-link" tabIndex="0">
                  <span data-v-776b7856="" className="label">Docs</span>
                </a>
              </span>
              <span data-v-61fec360="" className="main-btn" >
                <a href="https://medium.com/@detafinance" target="_blank" data-v-3238a8e3="" data-v-776b7856="" data-v-61fec360="" className="route-btn" data-component="navbar-link" tabIndex="0">
                  <span data-v-776b7856="" className="label">Blogs</span>
                </a>
              </span>
              <span data-v-61fec360="" className="main-btn" >
                <a href={whitepaper} target="_blank" data-v-3238a8e3="" data-v-776b7856="" data-v-61fec360="" className="route-btn" data-component="navbar-link" tabIndex="0">
                  <span data-v-776b7856="" className="label">Whitepaper</span>
                </a>
              </span>
              <span data-v-61fec360="" className="main-btn" >
                <a href="https://wag9wh5rgvi.typeform.com/to/AICOJpdW" target="_blank" data-v-3238a8e3="" data-v-776b7856="" data-v-61fec360="" className="route-btn" data-component="navbar-link" tabIndex="0">
                  <span data-v-776b7856="" className="label">Careers</span>
                </a>
              </span>
              <span data-v-61fec360="" className="main-btn" >
                <a href="https://giveaway.deta.io/" target="_blank" data-v-3238a8e3="" data-v-776b7856="" data-v-61fec360="" className="route-btn" data-component="navbar-link" tabIndex="0">
                  <span data-v-776b7856="" className="label">Win $300k</span>
                </a>
              </span>
            </span>
            <span className="ctas" data-v-61fec360="" style={{ position: 'relative' }} onClick={() => setToggle(!toggle)}>
              <span data-v-166997db="" data-v-61fec360="" style={{ translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: 1 }}>
                <div className="BtnNewContact">
                  Ecosystem <MdKeyboardArrowDown />
                </div>
                {/* <a data-v-58d387f4="" data-v-61fec360="" tabIndex="0" href="https://buy.elrond.com/" target="_blank" className="new-button -small -mint2 -false " aria-label="buy" data-v-166997db="" data-force-hover="false">
                  <div data-v-58d387f4="" className="button-container">
                    <div data-v-58d387f4="" className="button-box label -noselect">
                      <span data-v-58d387f4="" className="label-content">
                        <span data-v-58d387f4="">CONTACT US 
                        </span>
                      </span>
                    </div>
                  </div>
                </a> */}
              </span>
              {toggle &&

                <div className="ecosystem-dropdown">
                  <ul>
                    <li>
                      <a target="_blank" href="https://uni.deta.io">Uniflow</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://conx.deta.io"  >AI ConX</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://prox.deta.io"  >Pro X</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://swap.deta.io"  >Swap 2.0</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://wallet.deta.io"  >Deta Wallet</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://hardware.deta.io"  >Hardware Wallet</a>
                    </li>
                  </ul>
                </div>
              }
            </span>
          </div>
        </div>
      </nav>

      <MobileHeader />

      <div className="NewHeaderHome">
        <div className="HeaderHolderMain">
          <div className="video-container1" data-v-2f0efa76="">
            <video src={one} autoPlay='true' muted="muted" loop="loop" playsInline="" width={'100%'} height={'auto'} className="header-3d" data-v-2f0efa76=""></video>
          </div>
          <div className="HeadNew1">
            <h1 data-v-2f0efa76="">
              <div data-motion-id="header-title" data-motion-play-emitter="home-header-title" className="title -h2" data-v-2f0efa76="">
                <p data-v-2f0efa76="" >
                  <strong data-v-2f0efa76=""><span className="mobile-title mint HeadNewTxt1">Unlock the Power of Crypto with Deta Finance.</span><span className="mobile-desc"> Enter the AI powered ecosystem empowering traders and developers, while opening up limitless possibilities of liquidity aggregation and creating Authentic markets.</span> </strong>
                </p>
              </div>
            </h1>
          </div>
          <div className="HeadNew2">
            <div className="PresaleHeading">
              PRESALE DASHBOARD
            </div>
            <PreSale />
          </div>
        </div>
      </div>




    </>
  );
};

export default Header;
