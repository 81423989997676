import React from 'react'
import './Pages.css'
function LearnAll() {
    return (
        <div id="hero" className="section is--hero-eco fp-section active" data-anchor="one">
            <div className="container is--hero">
                <div className="hero">
                    <div className="hero_left-content">
                        <div data-w-id="aae73d76-56b8-e844-9448-d4984034a930" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} className="hero_h1-wrapper">
                            <div data-w-id="e27081cd-e27c-062c-eb6c-443d3d468b0c" className="hero_h1-wrapper-top">
                                <h1 className='hide-on-mobile'>Learn all<br /> about the<br />Token<br />Economy</h1>
                                <h1 className='hide-on-desktop'>Learn all about the Token Economy</h1>
                            </div>
                        </div>
                        <div data-w-id="a5ba792e-25cc-5e0e-4e31-12a275f66bf4" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} className="hero_paragraph-wrapper"><p data-w-id="2de376d7-b1c4-e50f-6f5d-2fced24e639d">Expand you capabilities and join the forefront of innovation. B part of the future and master the art of tokenization with deta Academy</p>
                        </div>
                        <div data-w-id="1143fd37-f928-b383-da45-dd91e0d1da45" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} className="hero_btn-wrapper">
                            <div className="btn_move-over-space"><a href="#download" className="main_btn ">
                                <div className="btn_content main">
                                    <div className="btn_text-wrapper">
                                        <div className="btn_text" >GET STARTED
                                        </div>
                                        <div className="btn_text _2" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>GET STARTED
                                        </div>
                                    </div>
                                </div>
                                <div className="btn_arrow-wrapper">
                                    <div className="btn_arrow-svg">
                                        <div className="html-embed w-embed"><svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.38709 12.7391L13 1M13 1L13 13M13 1L0.999999 0.999999" stroke="currentColor" strokeWidth="1.5"></path>
                                        </svg>
                                        </div>
                                    </div>
                                </div></a>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="cf699edf-bb39-5d14-7624-d789b5036b9b" style={{ transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} className="eco-hero_visual">
                        <div className="eco_bubble-wrapper _1">
                            <div className="hero_bubble">
                                <img src="https://uploads-ssl.webflow.com/63c78d71162028eaa057dc3c/63c79bc6749c0d182c788b26_icon%20(1).svg" loading="lazy" alt="" className="hero_bubble-svg" />
                                <div className="hero_bubble-text">Elevate yours skills with Brickken
                                </div>
                            </div>
                        </div>
                        <div className="eco_bubble-wrapper _2">
                            <div className="hero_bubble">
                                <img src="https://uploads-ssl.webflow.com/63c78d71162028eaa057dc3c/63c79bc6fa4126d89cf94d92_Icon%20(3).svg" loading="lazy" alt="" className="hero_bubble-svg" />
                                <div className="hero_bubble-text">Stay ahead of the curve
                                </div>
                            </div>
                        </div>
                        <div className="eco_bubble-wrapper _3">
                            <div className="hero_bubble">
                                <img src="https://uploads-ssl.webflow.com/63c78d71162028eaa057dc3c/63c79bc6162028da2e58be95_icon%20(2).svg" loading="lazy" alt="" className="hero_bubble-svg" />
                                <div className="hero_bubble-text">Lead in the Token Economy
                                </div>
                            </div>
                        </div>
                        <div data-poster-url="/assets/images/14.png" data-video-urls="/assets/images/14.png" data-autoplay="false" data-loop="true" data-wf-ignore="true" className="tokens_visual-image _1 w-background-video w-background-video-atom">

                        </div>
                        <img src="https://uploads-ssl.webflow.com/63c78d71162028eaa057dc3c/63e1f3b6d26a6cfedf6b6f71_Group%201000002407%20(2).svg" loading="eager" alt="" className="tokens_visual-mask _1" />
                        <img src="https://uploads-ssl.webflow.com/63c78d71162028eaa057dc3c/63d4fd242108fe8d9f52a11d_wqeqwe.svg" loading="eager" alt="" className="tokens_visual-mask-mobile _1" />
                        <img src="https://uploads-ssl.webflow.com/63c78d71162028eaa057dc3c/63cd0d5cd805d5935df88c39_Group%201000002401.svg" loading="lazy" alt="" className="tokens_visual-stroke" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LearnAll