import "./App.css";
import Header from "./Components/Header";
import Presale from "./Pages/PresalePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./Pages/landingpage/LandingPage.jsx";
import NetworkDialog from "./NetworkSwitch";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { useContext } from "react";
import { AppContext } from "./utils";
import { useNetwork } from "wagmi";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id) {
      setOpenNetworkSwitch(
        chain?.id !== 1 ? (chain?.id !== 56 ? true : false) : false
      );
    }
  }, [chain, account]);
  return (
    <>
      <NetworkDialog open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
