import React from 'react'
import icon1 from '../../assets/icons/icon1.png'
import icon2 from '../../assets/icons/icon2.png'
import icon3 from '../../assets/icons/icon3.png'
import icon4 from '../../assets/icons/icon4.png'
import icon5 from '../../assets/icons/icon5.png'
import icon6 from '../../assets/icons/icon6.png'
function TenetEcosystem() {
    return (
        <section className="sec-paradigm wf-section">
            <h2 className='title titleEco'>Deta Finance Ecosystem</h2>
            <div id="security" className="cont-1391">
                <div className="swiper-container swip1">
                    <div className="swiper-wrapper swip1">
                        <a href="http://UNI.deta.io">
                            <div id="w-node-_2637cc30-637a-e816-ea5b-3b4b8a79979a-c59cc6d4" className="swiper-slidee swip1">
                                <div className="icon-1 w-embed">
                                </div>
                                <div className='imageHolderDiv'>
                                    <img src={icon1} loading="lazy" alt="" className="image-68" />
                                </div>
                                <div className="wrp-text-slide marginZero">
                                    <div className="t-38 normal font-24px ">Liquidity Aggregator Exchange
                                    </div>
                                    <div className="box-size-0">
                                        <p className="par-18 _158 paddingTopMin">A liquidity aggregator exchange is a platform that consolidates liquidity from multiple sources, providing users with access to a wide range of trading options. It efficiently pools liquidity to enhance trading volumes and minimize slippage, resulting in a seamless trading experience.</p>
                                    </div>
                                </div>


                            </div>
                        </a>
                        <a href="http://PROX.deta.io">
                            <div id="w-node-_2637cc30-637a-e816-ea5b-3b4b8a79979a-c59cc6d4" className="swiper-slidee swip1">
                                <div className="icon-1 w-embed">
                                </div>
                                <div className='imageHolderDiv'>
                                    <img src={icon2} loading="lazy" alt="" className="image-68" />
                                </div>
                                <div className="wrp-text-slide marginZero">
                                    <div className="t-38 normal font-24px ">Perpetual Exchange Decentralized
                                    </div>
                                    <div className="box-size-0">
                                        <p className="par-18 _158 paddingTopMin">A perpetual exchange decentralized platform allows users to engage in perpetual contracts, which are derivative contracts with no expiry date. These contracts enable traders to speculate on the price of assets without the need for actual ownership, offering flexibility and continuous trading opportunities.</p>
                                    </div>
                                </div>


                            </div>
                        </a>
                        <a href="http://WALLET.deta.io">
                            <div id="w-node-_2637cc30-637a-e816-ea5b-3b4b8a79979a-c59cc6d4" className="swiper-slidee swip1">
                                <div className="icon-1 w-embed">
                                </div>
                                <div className='imageHolderDiv'>
                                    <img src={icon3} loading="lazy" alt="" className="image-68" />
                                </div>
                                <div className="wrp-text-slide marginZero">
                                    <div className="t-38 normal font-24px ">Multi-Chain Wallet
                                    </div>
                                    <div className="box-size-0">
                                        <p className="par-18 _158 paddingTopMin">A multi-chain wallet is a secure digital wallet that supports multiple blockchain networks. It enables users to store, manage, and transact various cryptocurrencies across different blockchains from a single interface, simplifying the management of diverse crypto assets.</p>
                                    </div>
                                </div>


                            </div>
                        </a>
                    </div>
                </div>
                <div className="swiper-container swip1">
                    <div className="swiper-wrapper swip1">
                        <a href="http://CONX.deta.io">
                            <div id="w-node-_2637cc30-637a-e816-ea5b-3b4b8a79979a-c59cc6d4" className="swiper-slidee swip1">
                                <div className="icon-1 w-embed">
                                </div>
                                <div className='imageHolderDiv'>
                                    <img src={icon4} loading="lazy" alt="" className="image-68" />
                                </div>
                                <div className="wrp-text-slide marginZero">
                                    <div className="t-38 normal font-24px ">AI Contract Deployment Platform
                                    </div>
                                    <div className="box-size-0">
                                        <p className="par-18 _158 paddingTopMin">An AI contract deployment platform leverages artificial intelligence to streamline the process of deploying smart contracts. By automating tasks like code analysis, testing, and optimization, it enhances efficiency and reduces errors, facilitating the seamless deployment of smart contracts on blockchain networks.</p>
                                    </div>
                                </div>


                            </div>
                        </a>
                        <a href="http://SWAP.deta.io">
                            <div id="w-node-_2637cc30-637a-e816-ea5b-3b4b8a79979a-c59cc6d4" className="swiper-slidee swip1">
                                <div className="icon-1 w-embed">
                                </div>
                                <div className='imageHolderDiv'>
                                    <img src={icon5} loading="lazy" alt="" className="image-68" />
                                </div>
                                <div className="wrp-text-slide marginZero">
                                    <div className="t-38 normal font-24px ">Swap 2.0 with Front-End Taxes
                                    </div>
                                    <div className="box-size-0">
                                        <p className="par-18 _158 paddingTopMin">Swap 2.0 is an advanced exchange mechanism that incorporates tax collection at the front end of the transaction. Unlike traditional approaches where smart contracts handle tax calculations during token sales, Swap 2.0 deducts taxes directly during the exchange process, ensuring accurate tax collection and optimizing the overall user experience.

                                        </p>
                                    </div>
                                </div>


                            </div>
                        </a>
                        <a href="http://hardware.deta.io">
                            <div id="w-node-_2637cc30-637a-e816-ea5b-3b4b8a79979a-c59cc6d4" className="swiper-slidee swip1">
                                <div className="icon-1 w-embed">
                                </div>
                                <div className='imageHolderDiv'>
                                    <img src={icon3} loading="lazy" alt="" className="image-68" />
                                </div>
                                <div className="wrp-text-slide marginZero">
                                    <div className="t-38 normal font-24px ">Hardware Wallet for Asset Storage

                                    </div>
                                    <div className="box-size-0">
                                        <p className="par-18 _158 paddingTopMin">A hardware wallet is a physical device designed to store and secure cryptocurrencies offline. It provides an extra layer of protection against hacking and online threats by keeping private keys offline. Hardware wallets are user-friendly and enable users to safely store and access their digital assets while minimizing the risk of unauthorized access.</p>
                                    </div>
                                </div>


                            </div>
                        </a>
                    </div>
                </div>

            </div></section>
    )
}

export default TenetEcosystem