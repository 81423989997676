import React from 'react'
import man from './man.png'
import logofull from './logofull.png'
import facebook from './facebook.png'
import discord from './discord.png'
import instagram from './instagram.png'
import medium from './medium.png'
import reddit from './reddit.png'
import telegram from './telegram.png'
import twitter from './twitter.png'
import youtube from './youtube.png'
import github from "./git.png"
import PrivacyPolicy from './PrivacyPolicy.pdf'
import TermsAndConditions from './TermsAndConditions.pdf'
import whitepaper from '../../assets/detawhitepaper.pdf'
import detaAudit from '../../assets/DetaAudit.pdf'

import './Footer.css'
const Footer = () => {
    const openPrivacyPolicy = () => {
        window.open(PrivacyPolicy, '_blank');
    };

    const openTermsAndConditions = () => {
        window.open(TermsAndConditions, '_blank');
    };
    const scrollToChart = () => {
        document
            .getElementById("DetaPieChart")
            ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
    }
    return (

        <div class="footerNewMain">
            <div className='footerinner'>
                <div className='footerinner1'>
                    <div className='footerlogo'>
                        <a href="https://deta.io/" target='_blank'>   <img src={logofull} alt="" /> </a>
                    </div>
                    <div className='footertxt1'>
                        "Deta Finance: Empowering the future of decentralized finance. Unlock innovative DeFi tools, harness AI developer capabilities, and trade with confidence on our advanced platforms. Experience seamless integration, robust security, and unparalleled support. Join us and embrace a new era of financial freedom. Discover the power of Deta Finance today."
                    </div>
                    <div className='footertxt2'>
                        <span><a className='hoverMint' target='_blank' href="http://uni.deta.io">UNIFLOW</a></span>|
                        <span className='margLeft'><a className='hoverMint' target='_blank' href="http://conx.deta.io ">CONX</a></span>|
                        <span className='margLeft'><a className='hoverMint' target='_blank' href="http://prox.deta.io ">PROX</a></span>|
                        <span className='margLeft'><a className='hoverMint' target='_blank' href="http://swap.deta.io ">SWAP 2.0</a></span>|
                        <span className='margLeft' ><a className='hoverMint'  target='_blank' href="http://hardware.deta.io ">HARDWARE WALLET</a></span>|
                        <span className='margLeft'><a className='hoverMint' target='_blank' href="http://wallet.deta.io">WALLET</a></span>
                    </div>

                </div>
                <div className='footerinner2'>
                    <div className='footerinner2-in'>
                        <img src={man} alt="" />

                    </div>
                </div>

                <div className='footerinner3'>
                    <div>
                        Follow us
                    </div>
                    <div className='socialIconsDiv'>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://www.facebook.com/profile.php?id=100093141941104">
                                <img src={facebook} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://twitter.com/detafinance">
                                <img src={twitter} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://instagram.com/deta.finance?igshid=NGExMmI2YTkyZg==">
                                <img src={instagram} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://medium.com/@detafinance">
                                <img src={medium} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href=" https://www.reddit.com/r/DetaEth?utm_source=share&utm_medium=android_app&utm_name=androidcss&utm_term=1&utm_content=share_button">
                                <img src={reddit} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://discord.gg/3S8ZwHWrze">
                                <img src={discord} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://t.me/detafinance">
                                <img src={telegram} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://youtube.com/@DetaFinance">
                                <img src={youtube} alt="" />
                            </a>
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <a target='_blank' href="https://github.com/Deta-Org">
                                <img src={github} alt="" />
                            </a>
                        </div>

                    </div>
                    <div style={{ margin: '10% 0px 5% 0px'}}>
                    <p style={{ fontSize: '14px',paddingBottom:"100px"}}>Deta Finance LLC.</p>
                    <p style={{ fontSize: '14px' }}>LLC Number : 3116LLC2023</p>
                    <p style={{ fontSize: '14px' }}>St. Vincent & the Grenadines.</p>

                   
                    </div>
                    <div className='doublecol'>
                        <div>
                            <span className='doubleColHead'> Explore </span>
                            <div className='margintop30'>
                                <a onClick={scrollToChart}>
                                    <p className='hoverMint'>Token</p>
                                </a>
                                <p className='hoverMint' onClick={()=>window.open(detaAudit,"_blank")}>Security</p>
                                <p ><a className='hoverMint' href="https://detatokens-organization.gitbook.io/deta-finance-documentation/" target="_blank">Docs</a></p>
                                <p ><a className='hoverMint' href="https://medium.com/@detafinance" target="_blank">Blogs</a></p>
                                <p ><a className='hoverMint' href={whitepaper} target="_blank">Whitepaper</a></p>
                                <p ><a className='hoverMint' href="https://wag9wh5rgvi.typeform.com/to/AICOJpdW" target="_blank">Careers</a></p>
                                <p ><a className='hoverMint' href="https://giveaway.deta.io/" target="_blank">Win $300k</a></p>

                            </div>
                        </div>
                        <div className='doubleCol2nd'>
                            <span className='doubleColHead'> Explore </span>
                            <div className='margintop30'>
                                <div className='margintop30 footertxt3-1'>
                                    <a className='zeromargin footertxt3-1' onClick={openPrivacyPolicy}>
                                        <p className='footertxt3-1'>Privacy Policy</p>
                                    </a>
                                    <a className='zeromargin footertxt3-1' onClick={openTermsAndConditions}>
                                        <p className='footertxt3-1'>Terms and Conditions</p>
                                    </a>
                                    {/* <a className='zeromargin footertxt3-1' ><p onClick={() => window.open(PrivacyPolicy)} className='footertxt3-1'>Privacy Policy</p></a>
                                    <a className='zeromargin footertxt3-1' ><p onClick={() => window.open(TermsAndConditions)} className='footertxt3-1'>Terms and Conditions</p></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='footertxt3'>
                    Copyright © 2023 Deta. All Rights Reserved.
                </div>
                <div className='footertxt3'>
                    Cryptocurrencies may not be regulated in your jurisdiction. 
The value of cryptocurrencies can go  down as well as up. Profits may be subject to capital gains tax
                </div>
            </div>
        </div>

    )
}

export default Footer