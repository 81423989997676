import React from 'react'
import '../new.css'
import one from '../assets/video/one.mp4'

function PresaleStages() {
    return (
        <div className='TableContainerMain'>

            {/* <video autoplay muted loop>
                <source src={one} type="video/mp4" />
            </video> */}
            <div className="video-container" data-v-2f0efa76="">
                <video src={one} autoPlay='true' muted="muted" loop="loop" playsInline="" width={'100%'} height={'auto'} className="header-3d" data-v-2f0efa76=""></video>
            </div>
            <div className='videoupperlayer'>

            </div>
            <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-047be0c" data-id="047be0c" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-92c2f5f elementor-widget elementor-widget-heading" data-id="92c2f5f" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default presalearea-text">Presale Stages</h2>		</div>
                            <br />
                        </div>
                        <div className=" elementor-element elementor-element-0737e2f elementor-widget elementor-widget-heading" data-id="0737e2f" data-element_type="widget" data-widget_type="heading.default">
                            <div className=" elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default presalearea-text2">Early Investor Benefits and Token Distribution</h2>		</div>
                            <br />
                        </div>
                        <div className="elementor-element elementor-element-7b691e1 elementor-widget elementor-widget-heading paddingright26" data-id="7b691e1" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default presalearea-text3">The presale phase of Deta Finance (DETA) presents an exclusive opportunity for early investors to participate in the project and secure their
                                    allocation of DETA tokens.
                                    <br />
                                    Presale Token Allocation and Distribution: Deta Finance has allocated 50% of the total DETA token supply for the presale phase.
                                    <br />
                                    This ensures a fair distribution of tokens among early investors, allowing them to be part of the project's growth and success. <br />
                                    The presale phase will consist of seven stages, with an equal amount of DETA tokens available at each stage.
                                </h2>		</div>
                            <br />
                        </div>
                        <div className="elementor-element elementor-element-4db2bc1 eael-table-align-center eael-dt-th-align-left eael-dt-td-align-left elementor-widget elementor-widget-eael-data-table" data-id="4db2bc1" data-element_type="widget" data-widget_type="eael-data-table.default">
                            <div className="elementor-widget-container">
                                <div className="eael-data-table-wrap" data-table_id="4db2bc1" data-custom_responsive="false">
                                    <table className="tablesorter eael-data-table center width100" id="eael-data-table-4db2bc1">
                                        <thead>
                                            <tr className="table-header">
                                                <th className="th-tr-table" id="" colSpan="">
                                                    <span className="data-table-header-text">STAGE (no)   </span></th>
                                                <th className="th-tr-table" id="" colSpan="">
                                                    <span className="data-table-header-text">TOKENS ($DETA)Header</span></th>
                                                <th className="th-tr-table" id="" colSpan="">
                                                    <span className="data-table-header-text">PRICE ($) </span></th>
                                                <th className="th-tr-table" id="" colSpan="">
                                                    <span className="data-table-header-text"> RAISED ($)</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">Stage 1</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">57,142,857</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content mint">$0.012</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">$685,714.284</div></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">Stage 2</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">57,142,857</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content mint">$0.020</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">$1,142,857.140</div></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">Stage 3</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">57,142,857</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content mint">$0.028</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">$1,600,000.004</div></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">Stage 4
                                                    </div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">57,142,857
                                                    </div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content mint"> $0.036</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">$2,057,142.852</div></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">Stage 5</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">57,142,857</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content mint">$0.044</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">$2,514,285.708</div></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">Stage 6</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">57,142,857</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content mint">$0.052</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">$2,971,428.564</div></div>
                                                </td>
                                            </tr>
                                            <tr className='table-tr'>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">Stage 7 </div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">57,142,857</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content mint">$0.060</div></div>
                                                </td>
                                                <td colSpan="" rowSpan="" className="table-td" id="">
                                                    <div className="td-content-wrapper"><div className="td-content">$3,428,571.420</div></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-147bf49 elementor-widget elementor-widget-text-editor" data-id="147bf49" data-element_type="widget" data-widget_type="text-editor.default">
                            <br />
                            <p className='mint'>
                                DETA Uniswap launch price : $0.063
                            </p>
                            <br />

                            <div className="elementor-widget-container">
                                Note: The sum of all 7 stages is: $14,400,000.972
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default PresaleStages