import ethPresaleAbi from "./ethPreSaleAbi.json";
import bnbPresaleAbi from "./bnbPresaleAbi.json";
import usdtAbi from "./usdtAbi.json";
import bnbUSDTAbi from "./bnbUSDTAbi.json";
import {
  bscPresaleAddress,
  bnbUsdtAddress,
  ethPresaleAddress,
  ethUsdtAddress,
} from "./environment";
import { Alert, Snackbar } from "@mui/material";
import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";

// read functions

export const bscPresaleReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: bscPresaleAddress,
    abi: bnbPresaleAbi,
    chainId: 56,
    functionName,
    args,
  });
  return data;
};

export const bscUsdtReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: bnbUsdtAddress,
    abi: bnbUSDTAbi,
    chainId: 56,
    functionName,
    args,
  });
  return data;
};

export const ethPresaleReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: ethPresaleAddress,
    abi: ethPresaleAbi,
    chainId: 1,
    functionName,
    args,
  });
  return data;
};

export const ethUsdtReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: ethUsdtAddress,
    abi: usdtAbi,
    chainId: 1,
    functionName,
    args,
  });
  return data;
};

/// write functions
export const bscPresaleWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: bscPresaleAddress,
    abi: bnbPresaleAbi,
    chainId: 56,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const bscUsdtWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: bnbUsdtAddress,
    abi: bnbUSDTAbi,
    chainId: 56,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const ethPresaleWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: ethPresaleAddress,
    abi: ethPresaleAbi,
    chainId: 1,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const ethUsdtWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: ethUsdtAddress,
    abi: usdtAbi,
    chainId: 1,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

// toast

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={20000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
        variant="filled"
        style={{ fontSize: "18px" }}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}
