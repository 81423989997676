import React from 'react'
import {BsArrowRight} from 'react-icons/bs'

function Roadmap() {
    return (

        <div className="sect-11 wf-section" style={{position : 'relative'}}>
            <BsArrowRight className='roadmap-icon'/>
            <div className="cont-1347 roadmap-1">
                <div className="h2-box">
                    <h2 className="h2-57 roadmmap">Roadmap</h2>
                </div>
                <div className="wrapper-roadmap">
                    <div className="column-roadmap">
                        <div className="t-20 tfn">Q1 2023
                        </div>
                        <div className="column-box-roadmap">
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Exchange launch &amp; <br />UI/UX</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>
                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Multi-Chain Wallet  &amp; <br />Expansion</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>
                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Initial Marketing &amp; <br />Campaign</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>
                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Liquidity Partnering &amp; <br />Begins</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>
                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">AI Contract &amp; <br />Development</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>
                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column-roadmap">
                        <div className="t-20 tfn">Q2 2023
                        </div>
                        <div className="column-box-roadmap">
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Exchange Enhancements, Liquidity</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>

                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Wallet Compatibility Expansion</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>

                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Educational Resources Launch</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>

                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Security Audit Upgrades</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>

                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Partnership Expansion</p>
                                <div className="ok w-embed"><svg width="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#00FFC2"></rect>

                                    <path d="M7.33317 10.3333L12.2498 5.41667C12.4026 5.26389 12.5971 5.1875 12.8332 5.1875C13.0693 5.1875 13.2637 5.26389 13.4165 5.41667C13.5693 5.56944 13.6457 5.76389 13.6457 6C13.6457 6.23611 13.5693 6.43056 13.4165 6.58333L7.9165 12.0833C7.74984 12.25 7.55539 12.3333 7.33317 12.3333C7.11095 12.3333 6.9165 12.25 6.74984 12.0833L4.58317 9.91667C4.43039 9.76389 4.354 9.56944 4.354 9.33333C4.354 9.09722 4.43039 8.90278 4.58317 8.75C4.73595 8.59722 4.93039 8.52083 5.1665 8.52083C5.40261 8.52083 5.59706 8.59722 5.74984 8.75L7.33317 10.3333Z" fill="black"></path>
                                </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column-roadmap">
                        <div className="t-20 tfn">Q3 2023
                        </div>
                        <div className="column-box-roadmap">
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">Decentralized Perpetual Exchange</p>
                            </div>
                            <div className="bg-gradient green">
                                <p className="p-14-roadmap">AI Platform Enhancements</p>
                            </div>
                            <div className="bg-gradient red-53">
                                <p className="p-14-roadmap">Referral Program Implementation</p>
                            </div>
                            <div className="bg-gradient red-53">
                                <p className="p-14-roadmap">Marketing and Awareness</p>
                            </div>
                            <div className="bg-gradient purp-53">
                                <p className="p-14-roadmap">Community Engagement</p>
                            </div>
                        </div>
                    </div>
                    <div className="column-roadmap">
                        <div className="t-20 tfn">Q4 2023
                        </div>
                        <div className="column-box-roadmap">
                            <div className="bg-gradient white-53">
                                <p className="p-14-roadmap">Swap 2.0 Implementation</p>
                            </div>
                            <div className="bg-gradient red-53">
                                <p className="p-14-roadmap">Platform Upgrades, Pairs</p>
                            </div>
                            <div className="bg-gradient purp-53">
                                <p className="p-14-roadmap">Partnership Discussions</p>
                            </div>
                            <div className="bg-gradient blue-53">
                                <p className="p-14-roadmap">Token Listing Exploration</p>
                            </div>
                            <div className="bg-gradient yellow-53">
                                <p className="p-14-roadmap">Team Expansion</p>
                            </div>
                        </div>
                    </div>
                    <div className="column-roadmap">
                        <div className="t-20 tfn">Q1 - Q4 2024
                        </div>
                        <div className="column-box-roadmap">
                            <div className="bg-gradient blue-53">
                                <p className="p-14-roadmap">Continuous Improvement, Iteration</p>
                            </div>
                            <div className="bg-gradient yellow-53">
                                <p className="p-14-roadmap">Analytics Dashboard Development</p>
                            </div>
                            <div className="bg-gradient white-53">
                                <p className="p-14-roadmap">Ecosystem Expansion, Partnerships</p>
                            </div>
                            <div className="bg-gradient blue-53">
                                <p className="p-14-roadmap">Developer Grant Program</p>
                            </div>
                            <div className="bg-gradient blue-53">
                                <p className="p-14-roadmap">Cross-Chain Compatibility</p>
                            </div>
                            <div className="bg-gradient blue-53">
                                <p className="p-14-roadmap">Global Marketing Campaigns</p>
                            </div>
                            <div className="bg-gradient blue-53">
                                <p className="p-14-roadmap">Community Engagement, Governance</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap