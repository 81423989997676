import { useState, useEffect, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './ScrollSection.css'
import video1 from '../assets/video/video1.mp4'
import video2 from '../assets/video/video2.mp4'
import video3 from '../assets/video/video3.mp4'
import video4 from '../assets/video/video4.gif'
import video5 from '../assets/video/video5.gif'
import { RingLoader } from "react-spinners";

// import laptop from '../assets/Images/laptop.png'



function ScrollSection() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
const [isLoading, setIsLoading] = useState(true);

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  const handleVideoError = () => {
    setIsLoading(false);
  };
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.play();
  }, []);

  return (
    <>
      <div className='SliderHolder' >

        <Carousel activeIndex={index} aut onSelect={handleSelect}>
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <section className="section1">
                <div className="one">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-9 mx-auto">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <div className='one '>
                              <h2 className='slidernum'>01 /05</h2>

                              <h3 className='slideHeadingtext'>PROX PERPETUAL DEX</h3>

                              <p className='slideHeadingsubtext'>Discover PROX, Deta Finance's cutting-edge perpetual decentralized exchange. Experience higher leverage, a wide range of trading pairs, and the power of decentralized trading, all in one seamless platform.</p>

                              <button className='sliderBtn'>
                                <a href="http://PROX.deta.io ">
                                  Learn more
                                </a>
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 mobileSlider">

                            <div className="div-block-7">
                              {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fd2f0037d5336076afa002_mobse.webp" loading="lazy" alt="" className="image-7 mob" /> */}
                              <div className="mask-phone">
                                {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 20vw, 285px" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021-p-500.webp 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp 966w" alt="" className="img-phone" /> */}
                                <div className="in-phone">
                                  <div className="black-bg-phone"></div>
                                  <div data-w-id="f0c153c5-dbf7-7bc6-3d1d-c8da55330946" className="img-1 w-embed">
                                    
      {isLoading && <div className="loader"></div>}

                                    <video ref={videoRef} autoPlay muted loop className="img-1" style={{ width: '100%', height: '100%' }}
                                      onLoadedData={handleVideoLoad}
                                   onError={handleVideoError}
                                    >
                                      <source src={video1} type="video/mp4" />
                                    </video>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="div-block8">
                                    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-500.png 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-800.png 800w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp 1052w" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 71vw, 1030px" alt="" className="image-6" />
                                </div> */}
                            </div>
                            {/* <div>
                                <video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
                                    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
                                </video>

                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Carousel.Item >
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <section className="section1">
                <div className="one">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-9 mx-auto">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <div className='one '>
                              <h2 className='slidernum'>02 /05</h2>

                              <h3 className='slideHeadingtext'>SWAP 2.0</h3>

                              <p className='slideHeadingsubtext'>
                              Swap 2.0 is an advanced exchange mechanism that incorporates tax collection at the front end of the transaction. Unlike traditional approaches where smart contracts handle tax calculations during token sales, Swap 2.0 deducts taxes directly during the exchange process, ensuring accurate tax collection and optimizing the overall user experience.
                                 </p>

                              <button className='sliderBtn'>
                                <a href="http://wallet.deta.io">
                                  Learn more
                                </a>
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 mobileSlider">

                            <div className="div-block-7">
                              {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fd2f0037d5336076afa002_mobse.webp" loading="lazy" alt="" className="image-7 mob" /> */}
                              <div className="mask-phone">
                                {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 20vw, 285px" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021-p-500.webp 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp 966w" alt="" className="img-phone" /> */}
                                <div className="in-phone">
                                  <div className="black-bg-phone"></div>
                                  <div data-w-id="f0c153c5-dbf7-7bc6-3d1d-c8da55330946" className="img-1 w-embed">
     
                                  {isLoading && <div className="loader"></div>}
                                    <video ref={videoRef} autoPlay muted loop className="img-1" style={{ width: '100%', height: '100%' }}
                                      onLoadedData={handleVideoLoad}
                                      onError={handleVideoError}
                                      
                                   >
                                      <source src={video2} type="video/mp4" />
                                    </video>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="div-block8">
                                    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-500.png 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-800.png 800w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp 1052w" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 71vw, 1030px" alt="" className="image-6" />
                                </div> */}
                            </div>
                            {/* <div>
                                <video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
                                    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
                                </video>

                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <section className="section1">
                <div className="one">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-9 mx-auto">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <div className='one '>
                              <h2 className='slidernum'>03 /05</h2>

                              <h3 className='slideHeadingtext'>WALLET</h3>

                              <p className='slideHeadingsubtext'>
                              A hardware wallet is a physical device designed to store and secure cryptocurrencies offline. It provides an extra layer of protection against hacking and online threats by keeping private keys offline. Hardware wallets are user-friendly and enable users to safely store and access their digital assets while minimizing the risk of unauthorized access.</p>

                              <button className='sliderBtn'>
                                <a href="http://hardware.deta.io ">
                                  Learn more
                                </a>
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 mobileSlider">

                            <div className="div-block-7">
                              {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fd2f0037d5336076afa002_mobse.webp" loading="lazy" alt="" className="image-7 mob" /> */}
                              <div className="mask-phone">
                                {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 20vw, 285px" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021-p-500.webp 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp 966w" alt="" className="img-phone" /> */}
                                <div className="in-phone">
                                  <div className="black-bg-phone"></div>
                                  <div data-w-id="f0c153c5-dbf7-7bc6-3d1d-c8da55330946" className="img-1 w-embed">
                                    
      {isLoading && <div className="loader"></div>}
                                    <video ref={videoRef} autoPlay muted loop className="img-1" style={{ width: '100%', height: '100%' }}
                                       onLoadedData={handleVideoLoad}
                                       onError={handleVideoError}
                                       
                                    >
                                      <source src={video3} type="video/mp4" />
                                    </video>

                                    
                                  </div>
                                </div>
                              </div>
                              {/* <div className="div-block8">
                                    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-500.png 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-800.png 800w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp 1052w" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 71vw, 1030px" alt="" className="image-6" />
                                </div> */}
                            </div>
                            {/* <div>
                                <video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
                                    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
                                </video>

                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <section className="section1">
                <div className="one">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-10 mx-auto">
                        <div className="row align-items-center">
                          <div className="col-lg-6 my-auto">
                            <div className='one '>
                              <h2 className='slidernum'>04 /05</h2>

                              <h3 className='slideHeadingtext'>UNIFLOW</h3>

                              <p className='slideHeadingsubtext'>Discover a new era of decentralized trading with Deta Finance's revolutionary DEX and Swap 2.0. Experience seamless, secure, and efficient swaps on our cutting-edge platform. Harness the power of liquidity and redefine your trading experience in the world of decentralized finance. </p>

                              <button className='sliderBtn'>
                                <a href="http://UNI.deta.io">
                                  Learn more
                                </a>
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 mx-auto my-auto">
                            <div style={{ height: '100%' }}>
                              <div className='image-wrap wid380'>
                                <img src={video4} alt="" width={'200px'} height={'474px'} />
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <section className="section1">
                <div className="one">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-10 mx-auto">
                        <div className="row align-items-center">
                          <div className="col-lg-6 my-auto">
                            <div className='one '>
                              <h2 className='slidernum'>05 /05</h2>

                              <h3 className='slideHeadingtext'>PROX (Web)</h3>

                              <p className='slideHeadingsubtext'>
                              A multi-chain wallet is a secure digital wallet that supports multiple blockchain networks. It enables users to store, manage, and transact various cryptocurrencies across different blockchains from a single interface, simplifying the management of diverse crypto assets.</p>

                              <button className='sliderBtn'>
                                <a href="http://conx.deta.io ">
                                  Learn more
                                </a>
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 mx-auto my-auto">
                            <div style={{ height: '100%' }}>
                              <div className='image-wrap wid380'>
                                <img src={video5} alt="" width={'200px'} height={'474px'} />
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className='SliderHolderOnMobile'>
        <Carousel className='Sliderformobile' activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <div className='ResponsiveSlider-slide'>
                <div className='ResponsiveSliderNum'>
                  01 /05
                </div>
                <div className='ResponsiveSliderTxt1'>
                  PROX PERPETUAL DEX
                </div>

                <div className='ResponsiveSliderMobile'>
                  <div className="col-lg-6 mobileSlider">

                    <div className="div-block-7">
                      {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fd2f0037d5336076afa002_mobse.webp" loading="lazy" alt="" className="image-7 mob" /> */}
                      <div className="mask-phone">
                        {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 20vw, 285px" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021-p-500.webp 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp 966w" alt="" className="img-phone" /> */}
                        <div className="in-phone">
                          <div className="black-bg-phone"></div>
                          <div data-w-id="f0c153c5-dbf7-7bc6-3d1d-c8da55330946" className="img-1 w-embed">
                      
      {isLoading && <div className="loader"></div>}

                            <video ref={videoRef} autoPlay muted loop className="img-1" style={{ width: '100%', height: '100%' }}
                               onLoadedData={handleVideoLoad}
                               onError={handleVideoError}
                               
                            >
                              <source src={video1} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      </div>
                      {/* <div className="div-block8">
    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-500.png 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-800.png 800w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp 1052w" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 71vw, 1030px" alt="" className="image-6" />
</div> */}
                    </div>
                    {/* <div>
<video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
</video>

</div> */}
                  </div>
                </div>
                <div className='ResponsiveSliderDesc'>
                  Discover PROX, Deta Finance's cutting-edge perpetual decentralized exchange. Experience higher leverage, a wide range of trading pairs, and the power of decentralized trading, all in one seamless platform.
                </div>
                <div className='centerBtn'>
                  <button className='sliderBtn'>
                    <a href="http://PROX.deta.io">
                      Learn more
                    </a>
                  </button>

                </div>
              </div>
            </div>
          </Carousel.Item >
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <div className='ResponsiveSlider-slide'>
                <div className='ResponsiveSliderNum'>
                  02 /05
                </div>
                <div className='ResponsiveSliderTxt1'>
                SWAP 2.0
                </div>

                <div className='ResponsiveSliderMobile'>
                  <div className="col-lg-6 mobileSlider">

                    <div className="div-block-7">
                      {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fd2f0037d5336076afa002_mobse.webp" loading="lazy" alt="" className="image-7 mob" /> */}
                      <div className="mask-phone">
                        {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 20vw, 285px" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021-p-500.webp 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp 966w" alt="" className="img-phone" /> */}
                        <div className="in-phone">
                          <div className="black-bg-phone"></div>
                          <div data-w-id="f0c153c5-dbf7-7bc6-3d1d-c8da55330946" className="img-1 w-embed">
                         
      {isLoading && <div className="loader"></div>}
                            <video ref={videoRef} autoPlay muted loop className="img-1" style={{ width: '100%', height: '100%' }}
                                onLoadedData={handleVideoLoad}
                                onError={handleVideoError}
                                
                            >
                              <source src={video2} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      </div>
                      {/* <div className="div-block8">
    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-500.png 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-800.png 800w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp 1052w" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 71vw, 1030px" alt="" className="image-6" />
</div> */}
                    </div>
                    {/* <div>
<video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
</video>

</div> */}
                  </div>
                </div>
                <div className='ResponsiveSliderDesc'>
                 Swap 2.0 is an advanced exchange mechanism that incorporates tax collection at the front end of the transaction. Unlike traditional approaches where smart contracts handle tax calculations during token sales, Swap 2.0 deducts taxes directly during the exchange process, ensuring accurate tax collection and optimizing the overall user experience.
                </div>
                <div className='centerBtn'>
                  <button className='sliderBtn'>
                    <a href="http://PROX.deta.io">
                      Learn more
                    </a>
                  </button>

                </div>
              </div>
            </div>
          </Carousel.Item >
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <div className='ResponsiveSlider-slide'>
                <div className='ResponsiveSliderNum'>
                  03 /05
                </div>
                <div className='ResponsiveSliderTxt1'>
                  WALLET
                </div>

                <div className='ResponsiveSliderMobile'>
                  <div className="col-lg-6 mobileSlider">

                    <div className="div-block-7">
                      {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fd2f0037d5336076afa002_mobse.webp" loading="lazy" alt="" className="image-7 mob" /> */}
                      <div className="mask-phone">
                        {/* <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 20vw, 285px" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021-p-500.webp 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp 966w" alt="" className="img-phone" /> */}
                        <div className="in-phone">
                          <div className="black-bg-phone"></div>
                          <div data-w-id="f0c153c5-dbf7-7bc6-3d1d-c8da55330946" className="img-1 w-embed">
                        
      {isLoading && <div className="loader"></div>}
                            <video ref={videoRef} autoPlay muted loop className="img-1" style={{ width: '100%', height: '100%' }}
                                onLoadedData={handleVideoLoad}
                                onError={handleVideoError}
                                
                            >
                              <source src={video3} type="video/mp4" />
                            </video>
                          </div>
                        </div>
                      </div>
                      {/* <div className="div-block8">
    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-500.png 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-800.png 800w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp 1052w" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 71vw, 1030px" alt="" className="image-6" />
</div> */}
                    </div>
                    {/* <div>
<video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
</video>

</div> */}
                  </div>
                </div>
                <div className='ResponsiveSliderDesc'>
                A hardware wallet is a physical device designed to store and secure cryptocurrencies offline. It provides an extra layer of protection against hacking and online threats by keeping private keys offline. Hardware wallets are user-friendly and enable users to safely store and access their digital assets while minimizing the risk of unauthorized access.</div>
                <div className='centerBtn'>
                  <button className='sliderBtn'>
                    <a href="http://PROX.deta.io">
                      Learn more
                    </a>
                  </button>

                </div>
              </div>
            </div>
          </Carousel.Item >
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <div className='ResponsiveSlider-slide'>
                <div className='ResponsiveSliderNum'>
                  04 /05
                </div>
                <div className='ResponsiveSliderTxt1'>
                  UNIFLOW
                </div>

                <div className='ResponsiveSliderMobile'>
                  <div className="col-lg-6 mobileSlider">
<br />
                    <div className="laptop-Responsive">
                      <img src={video4} alt="" />
                    </div>
<br />
                    {/* <div>
<video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
</video>

</div> */}
                  </div>
                </div>
                <div className='ResponsiveSliderDesc'>
                  Discover a new era of decentralized trading with Deta Finance's revolutionary DEX and Swap 2.0. Experience seamless, secure, and efficient swaps on our cutting-edge platform. Harness the power of liquidity and redefine your trading experience in the world of decentralized finance.
                </div>
                <div className='centerBtn'>
                  <button className='sliderBtn'>
                    <a href="http://PROX.deta.io">
                      Learn more
                    </a>
                  </button>

                </div>
              </div>
            </div>
          </Carousel.Item >
          <Carousel.Item interval={5000}>
            <div className='SliderMain'>
              <div className='ResponsiveSlider-slide'>
                <div className='ResponsiveSliderNum'>
                  05 /05
                </div>
                <div className='ResponsiveSliderTxt1'>
                  PROX (Web)
                </div>

                <div className='ResponsiveSliderMobile'>
                  <div className="col-lg-6 mobileSlider">
<br />
                    <div className="laptop-Responsive">
                      <img src={video5} alt="" />
                    </div>
                    <br />

                    {/* <div>
<video ref={videoRef} controls loop width={'100%'} height={'100%'}> 
    <source src={'/assets/video/video-1.mp4'} type="video/mp4" />
</video>

</div> */}
                  </div>
                </div>
                <div className='ResponsiveSliderDesc'>
                A multi-chain wallet is a secure digital wallet that supports multiple blockchain networks. It enables users to store, manage, and transact various cryptocurrencies across different blockchains from a single interface, simplifying the management of diverse crypto assets.  </div>
                <div className='centerBtn'>
                  <button className='sliderBtn'>
                    <a href="http://PROX.deta.io">
                      Learn more
                    </a>
                  </button>

                </div>
              </div>
            </div>
          </Carousel.Item >


        </Carousel>
      </div>
    </>
  );
}

export default ScrollSection;