import React from 'react';

const CustomProgressBar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: '100%',
    backgroundColor: 'whitesmoke',
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    textAlign: 'right',
    transition: 'width 0.5s ease', // Add transition property for smooth effect
  };

  const progresstext = {
    color: 'black',
    fontWeight: 600,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}></div>
    </div>
  );
};

export default CustomProgressBar;
