import React, { useState, useRef } from "react";
import Footer from "../../Components/Footer/Footer.jsx";
import Header from "./Header";
import { useEffect } from "react";
import GetInvolved from "./GetInvolved";
import LearnAll from "../LearnAll";
import TenetEcosystem from "./TenetEcosystem";
import Roadmap from "../Roadmap";
import Backers from "../Backers";
import Modekit from "../Modekit";
import gif from '../../assets/Images/gif.gif'
import '../../App.css'
import SecondSlider from "../../Components/shared/SecondSlider";
import { Introducing } from "../Introducing";
import ScrollSlider from "../../Components/ScrollSlider";
import PresaleStages from "../../Components/PresaleStages";
import Tokenomics from "../../Components/Tokenomics";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ScrollSection from "../../Components/ScrollSection.jsx";
// import PreSale from "../../Components/shared/PreSale.jsx";
import preloaderVideo from '../../assets/video/preloaderVideo.mp4'
import CustomProgressBar from "./CustomProgressBar.jsx";
import whitepaper from '../../assets/detawhitepaper.pdf'
import legalOpinion from '../../assets/LegalOpinion.pdf'
import detaAudit from '../../assets/DetaAudit.pdf'


// import { LinearProgress } from "@mui/material";


// import { LinearProgress, withStyles } from '@material-ui/core';


const LandingPage = () => {

  const [scrollHeight, setScrollHeight] = useState(0);
  const [showDiv, setShowDiv] = useState(false);
  const [showPreloader, setshowPreloader] = useState(false)

  const [progressBarValue, setSrogressBarValue] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setSrogressBarValue(10);
    }, 400);
    setTimeout(() => {
      setSrogressBarValue(20);
    }, 800);
    setTimeout(() => {
      setSrogressBarValue(30);
    }, 1200);
    setTimeout(() => {
      setSrogressBarValue(40);
    }, 1600);
    setTimeout(() => {
      setSrogressBarValue(50);
    }, 2000);
    setTimeout(() => {
      setSrogressBarValue(60);
    }, 2400);
    setTimeout(() => {
      setSrogressBarValue(70);
    }, 2800);
    setTimeout(() => {
      setSrogressBarValue(80);
    }, 3200);
    setTimeout(() => {
      setSrogressBarValue(90);
    }, 3600);
    setTimeout(() => {
      setSrogressBarValue(100);
    }, 4000);
    // setTimeout(() => {
    //   setSrogressBarValue(65);
    // }, 35000);
    // setTimeout(() => {
    //   setSrogressBarValue(95);
    // }, 38000);
    // setTimeout(() => {
    //   setSrogressBarValue(100);
    // }, 40000);

  }, [])
  useEffect(() => {
    if (progressBarValue == 100) {
      console.log(progressBarValue)
      setshowPreloader(false)
    }
  })


  // const videoRef = useRef(null);

  // const playVideo = () => {
  //   if (videoRef.current) {
  //     videoRef.current.play();
  //   }
  // };
  // playVideo()
  // useEffect(() => {

  //   if (showDiv) {
  //     const timer = setTimeout(() => {
  //       setShowDiv(false);
  //     }, 2000);
  //     return () => clearTimeout(timer);
  //   }
  //   const handleScroll = () => {
  //     console.log(window.scrollY);
  //     setScrollHeight(window.scrollY)
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [showDiv]);

  const copyToClipboard = (link) => {
    console.log(link);
    navigator.clipboard.writeText(link);
    setShowDiv(true);

    setTimeout(() => {
      setShowDiv(false);
    }, 1000);
  };



  return (
    <>
      
      <div className={`PreloaderVideoContainer ${showPreloader ? 'showpreloader' : ''}`}>
       
        <div className="video-containerpreloader" data-v-2f0efa76="">
          <video autoPlay="autoplay" muted="muted" loop="loop" src={preloaderVideo} playsInline="" width={'100%'} height={'auto'} className="header-3d" data-v-2f0efa76=""></video>
        </div>
        <div className="LinearProgressBar">
          <CustomProgressBar bgcolor="#00ffe4" progress={progressBarValue} height={5} />
          <p style={{ textAlign: 'center' }}>{progressBarValue}%</p>
        </div>
      </div>
      <div className="certik-wrapper">
<img src="/assets/images/certick.jpeg" onClick={()=>window.open("https://skynet.certik.com/projects/deta-finance","_blank")}/>
        </div>
      <div className={`LandingPageAfterPreloader ${showPreloader ? '' : 'showLandingPage'}`} id="__nuxt" style={{ position: 'relative' }}>
        {showDiv && (
          <div className={`transition-div ${showDiv ? "show" : ""}`}>
            <p>Copy successfully</p>
          </div>
        )}
        <div id="__layout">
          <div data-v-8ce32fb0="">

            <div data-v-06913c84="" data-v-e050f6a8="" data-v-8ce32fb0="">
              <div className="page scroll-wrapper" data-v-114d1840="" data-v-06913c84="">
                <div className="scroll-content" data-v-114d1840="" >
                  <div data-v-114d1840="" data-v-06913c84="">
                    <Header />
                    <main data-v-114d1840="" data-v-06913c84="">
                      <section data-hide-navbar="&quot;sQ#J6ak53Ux9^rr" className="power-of-multiversx" data-v-304e614a="" data-v-06913c84="" data-v-114d1840="">
                        <div className="intro" data-v-304e614a="" style={{ translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)' }}>
                          <div className="row expanded">
                            {/* <div className="section-title column xxlarge-17 xxlarge-offset-1 small-offset-2 xsmall-26 xsmall-offset-4 -light" data-v-2d95033d="">
                              <h2 className="container" data-v-2d95033d="">
                                <span className="number" data-v-2d95033d="">
                                  <img src="/assets/icons/mx-square-number-1.svg" alt="chapter-number" className="number-svg" data-v-2d95033d="" />

                                </span>
                                <span className="title -subtitle" data-v-2d95033d="">The Power of MultiversX
                                </span>
                              </h2>
                            </div> */}
                            <div className="section-description column  -light" data-v-2e446142="">
                              <h3 className="-h4 description-content" data-v-2e446142="">
                                <div data-motion-id="section-description margTop50" data-motion-attr="color: #8D9297" data-v-2e446142="">
                                  <p data-v-2e446142="" className=" lineHeightCustom margTop120 unlock-power unlock-powernew">
                                    <strong data-v-2e446142="" className="unlock-power2">Unlock the <span className="mint"> Power</span> of Crypto with <span className="mint"> Deta Finance.</span></strong>

                                    <p className="descUnlockNew2">
                                      The ultimate DeFi platform offering a user-friendly experience for trader and developers! Enjoy a liquidity aggregator, AI contract creation, multichain wallet, Swap2.0 exchange, and perpetual trading. Explore the future of finance with Deta today!
                                    </p>
                                  </p>
                                </div>
                              </h3>
                            </div>
                          </div>
                          <div className="row expanded align-center">
                            
                            <div className="video-new-container">
                              <div className="vidoe-new-inner">
                                <iframe rel="dns-prefetch" width="100%" height="auto" src="https://www.youtube.com/embed/B45F49yNF6U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                              </div>
                            </div>
                          </div>
                          <TenetEcosystem />
                          <ScrollSection />
                          {/* <ScrollSlider /> */}

                          <section className="sec-two margin-top-neg" >
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-8 my-auto">
                                  <div className="hero-tagline -h1">
                                    <p className="p-1 mint">The Deta Finance (DETA) Token.<br />
                                      {/* <span style={{ color: '#00ffe4' }} >Today.
                                      </span> */}
                                    </p>
                                  </div>
                                  <div className="faq-cta-container" >
                                    <p className="p-2 pTokenDesc">Deta Finance introduces its native utility token, DETA, which operates as an ERC20 token deployed on the
                                      Ethereum blockchain. With a dedicated smart contract address. </p>
                                    <div className="copy-url" onClick={() => {
                                      copyToClipboard('0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5');
                                    }}><span >
                                        <a className="allstylenone" href="https://etherscan.io/address/0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5#code">

                                          0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5
                                        </a>
                                      </span><button><ContentCopyIcon /></button></div>

                                    <div className="button-wrap">
                                      <button onClick={()=>window.open(detaAudit,"_blank")}>DETA AUDIT</button>
                                      <button onClick={() => window.open(whitepaper, "_blank")}>WHITEPAPER</button>
                                      <button onClick={()=>window.open(legalOpinion,"_blank")}>LEGAL OPINION</button>
                                    </div>
                                  
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center flex-column flex-md-row flex-lg-column col-lg-4 mx-auto my-auto">
                                  <img src="/assets/images/image-1.gif" alt="" style={{ margin: "20px", marginInline: 'auto' }} />
                                  <img src="/assets/images/TokenBadge.svg" onClick={()=>window.open("https://www.team.finance/view-coin/0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5?name=Deta","_blank")}  alt="" style={{ margin: "20px", marginInline: 'auto' }} />
                                </div>
                                   
                              </div>
                            </div>
                            <div className="">


                            </div>
                          </section>

                          {/* <Introducing /> */}
                          <PresaleStages />

                          <Tokenomics />


                          <Roadmap />
                          {/* <LearnAll /> */}
                          <SecondSlider />
                          <Modekit />
                          <Backers />
                          <GetInvolved />

                        </div>
                      </section>

                    </main>
                    <Footer />
                  </div>
                </div>
              </div>
              <div className="typeform-wrapper fabric" data-v-2f7597e0="" data-v-06913c84="" style={{}} id="next">
                <div className="typeform-container" data-v-2f7597e0="">
                </div>
                <button tabIndex="0" aria-label="Close modal" className="close-btn new-button -small -neutral750 -close " data-v-2f7597e0="" data-force-hover="false">
                  <div className="button-container" >
                    <div className="button-box icon" >
                      <div className="icon-content" ><i className="icon -close -s20 -white" data-v-a847d21a="" >
                        <svg data-v-a847d21a="" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                          <path data-v-a847d21a="" d="M6.63812 5.87282L14.3032 13.5379L13.234 14.607L5.56898 6.94196L6.63812 5.87282Z" className="fill">
                          </path>
                          <path data-v-a847d21a="" d="M14.5648 6.90377L6.89978 14.5688L5.83064 13.4997L13.4957 5.83462L14.5648 6.90377Z" className="fill">
                          </path>
                        </svg>
                      </i>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className="typeform-wrapper worlds" data-v-2f7597e0="" data-v-06913c84="" style={{}}>
                <div className="typeform-container" data-v-2f7597e0="">
                </div> <button tabIndex="0" aria-label="Close modal" className="close-btn new-button -small -neutral750 -close " data-v-2f7597e0="" data-force-hover="false">
                  <div className="button-container" >
                    <div className="button-box icon" >
                      <div className="icon-content" ><i className="icon -close -s20 -white" data-v-a847d21a="" >
                        <svg data-v-a847d21a="" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                          <path data-v-a847d21a="" d="M6.63812 5.87282L14.3032 13.5379L13.234 14.607L5.56898 6.94196L6.63812 5.87282Z" className="fill"></path>
                          <path data-v-a847d21a="" d="M14.5648 6.90377L6.89978 14.5688L5.83064 13.4997L13.4957 5.83462L14.5648 6.90377Z" className="fill"></path>
                        </svg>
                      </i>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div data-from="" className="page-transition" data-v-df8db190="" data-v-8ce32fb0="" style={{ pointerEvents: 'none', opacity: '0' }}>
              <div className="inner" data-v-df8db190=""> </div>
            </div>
            <div data-loading="true" className="video-modal" data-v-103c0ec6="" data-v-8ce32fb0="" style={{ visibility: 'hidden' }}>
              <div className="video-modal-wrapper" data-v-103c0ec6="">
                <button tabIndex="0" type="icon" className="close-modal-btn new-button -small -neutral750 -close " data-v-103c0ec6="" data-force-hover="false">
                  <div className="button-container" >
                    <div className="button-box icon" >
                      <div className="icon-content" ><i className="icon -close -s20 -white" data-v-a847d21a="" >
                        <svg data-v-a847d21a="" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
                          <path data-v-a847d21a="" d="M6.63812 5.87282L14.3032 13.5379L13.234 14.607L5.56898 6.94196L6.63812 5.87282Z" className="fill"></path>
                          <path data-v-a847d21a="" d="M14.5648 6.90377L6.89978 14.5688L5.83064 13.4997L13.4957 5.83462L14.5648 6.90377Z" className="fill"></path>
                        </svg>
                      </i>
                      </div>
                    </div>
                  </div>
                </button>
                <div className="video-container" data-v-103c0ec6="">
                  <div className="video-player-wrapper" data-v-103c0ec6="">
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >

    </>
  );
};

export default LandingPage;


<div className="div-block-7">
  <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fd2f0037d5336076afa002_mobse.webp" loading="lazy" alt="" className="image-7 mob" />
  <div className="mask-phone">
    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 20vw, 285px" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021-p-500.webp 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fe0f9f8cf9eccc8c5ba9a0_apple-iphone-13-pro-max-2021.webp 966w" alt="" className="img-phone" />
    <div className="in-phone">
      <div className="black-bg-phone"></div>
      <div data-w-id="f0c153c5-dbf7-7bc6-3d1d-c8da55330946" className="img-1 w-embed" style="will-change: opacity; opacity: 1;">
        <video className="img-1" style="width:100%; height:100%" poster="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/63fc5fd969b16e51e8fba346_Wallet-cover0.webp" data-autoplay="" autoplay="" loop="" muted="" playsinline="">
        </video>
      </div>
    </div>
  </div>
  <div className="div-block8">
    <img src="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp" srcset="https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-500.png 500w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290-p-800.png 800w, https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6418c0d913e20308e0309674_Frame%201290.webp 1052w" sizes="(max-width: 479px) 100vw, (max-width: 1439px) 71vw, 1030px" alt="" className="image-6" />
  </div>
</div>