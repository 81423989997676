import React, { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Link } from 'react-router-dom'
import whitepaper from '../../assets/detawhitepaper.pdf'
import detaAudit from '../../assets/DetaAudit.pdf'

function MobileHeader() {
    const [toggle, setToggle] = useState(false)
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const scrollToChart = () => {
        document
            .getElementById("DetaPieChart")
            ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
            setToggle(false)
    }
    return (
        <nav id='show-on-mobile'>

            <div data-v-61fec360="" className="row align-center expanded" data-component="navbar">
                <div data-v-61fec360="" className="navbar-main column xxlarge-30 xsmall-28">
                    <span data-v-61fec360="" className="logotype" >
                        <a target="_blank" href="https://deta.io/" data-v-61fec360="" aria-current="page" className="nuxt-link-exact-active nuxt-link-active" aria-label="Home button">
                            <img src="/assets/logo/logo-light.png" alt="" />
                        </a>
                    </span>
                    <span data-v-61fec360="" className="burger-container" style={{ translate: 'none', rotate: 'none', scale: 'none', transform: 'translate(0px, 0px)', opacity: '1' }}>
                        <button data-v-61fec360="" aria-label="Menu button" className="burger" onClick={() => setToggle(!toggle)}>
                            <div data-v-61fec360="" className="burger-icon">
                                <span data-v-61fec360="" className="line" style={{ backgroundColor: 'white', translate: 'none', rotate: 'none', scale: 'none', transform: toggle ? 'rotate(34deg) scale(0.92, 0.92)' : 'translate(0%, 150%)' }}></span>
                                <span data-v-61fec360="" className="line" style={{ backgroundColor: 'white', translate: 'none', rotate: 'none', scale: 'none', transform: toggle ? 'rotate(-34deg) scale(0.92, 0.92)' : 'translate(0%, -150%)' }}></span>
                            </div></button></span>
                </div>
            </div>
            {toggle
                &&
                <div data-v-25f42651="" data-v-61fec360="" data-component="navbar-menu" style={{ zIndex: '999' }}>
                    <div data-v-25f42651="" className="click-outside-catcher" style={{ opacity: 1 }}>

                    </div>
                    <div data-v-25f42651="" className="background" >

                    </div>
                    <div data-v-25f42651="" className="menu-container">
                        <div data-v-25f42651="" className="menu-main -h2 -bold">
                            <ul data-v-25f42651="" className="menu-list -root">
                                <li data-v-25f42651="" onClick={scrollToChart}>
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <a data-v-3238a8e3="" data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label">Token</span></a>
                                    </div>
                                </li>
                                <li data-v-25f42651="" >
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <a data-v-3238a8e3="" href={detaAudit} data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label" >Security</span></a>
                                    </div>
                                </li>
                                <li data-v-25f42651="" >
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <a href=" https://detatokens-organization.gitbook.io/deta-finance-documentation/" target="_blank" data-v-3238a8e3="" data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label">Docs</span></a>
                                    </div>
                                </li>
                                <li data-v-25f42651="" >
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <a href="https://medium.com/@detafinance" target="_blank" data-v-3238a8e3="" data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label">Blogs</span></a>
                                    </div>
                                </li>
                                <li data-v-25f42651="" >
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <a href={whitepaper} target="_blank" data-v-3238a8e3="" data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label">Whitepaper</span></a>
                                    </div>
                                </li>
                                <li data-v-25f42651="" >
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <a href="https://wag9wh5rgvi.typeform.com/to/AICOJpdW" target="_blank" data-v-3238a8e3="" data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label">Careers</span></a>
                                    </div>
                                </li>
                                <li data-v-25f42651="" >
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <a href="https://giveaway.deta.io/" target="_blank" data-v-3238a8e3="" data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label">Win $300K</span></a>
                                    </div>
                                </li>
                                <li data-v-25f42651="" style={{ position: 'relative' }} onClick={() => setToggleDropdown(!toggleDropdown)}>
                                    <div data-v-25f42651="" className="menu-btn-wrapper" >
                                        <Link data-v-3238a8e3="" data-v-25f42651="" className="menu-btn -root">
                                            <span data-v-25f42651="" className="dash"></span>
                                            <span data-v-25f42651="" className="label">Ecosystem <MdKeyboardArrowDown /></span></Link>
                                    </div>
                                    {toggleDropdown &&

                                        <div className="ecosystem-dropdown">
                                            <ul>
                                                <li>
                                                    <a target='_blank' href="https://UNI.deta.io">Uniflow</a>
                                                </li>
                                                <li>
                                                    <a target='_blank' href="https://CONX.deta.io"  >AI ConX</a>
                                                </li>
                                                <li>
                                                    <a target='_blank' href="https://PROX.deta.io"  >Pro X</a>
                                                </li>
                                                <li>
                                                    <a target='_blank' href="https://SWAP.deta.io"  >Swap 2.0</a>
                                                </li>
                                                <li>
                                                    <a target='_blank' href="https://wallet.deta.io"  >Deta Wallet</a>
                                                </li>
                                                <li>
                                                    <a target='_blank' href="https://hardware.deta.io" className='mt-3 mb-3'  >Hardware Wallet</a>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </li>
                            </ul>

                        </div>
                        {/* <div data-v-25f42651="" className="menu-footer row expanded">
                        <span data-v-25f42651="" className="background" ></span>
                        <div data-v-25f42651="" className="menu-price" >
                            <div data-v-25f42651="" className="-caption">EGLD Price
                            </div>
                            <div data-v-25f42651="" className="price -bold">$37.45
                            </div>
                        </div>
                        <div data-v-25f42651="" className="menu-ctas">
                            <span data-v-166997db="" data-v-25f42651="">
                                <a data-v-58d387f4="" data-v-25f42651="" tabindex="0" href="https://buy.elrond.com/" target="_blank" className="new-button -small -mint -false " aria-label="buy" data-v-166997db="" data-force-hover="false">
                                    <div data-v-58d387f4="" className="button-container">
                                        <div data-v-58d387f4="" className="button-box label -noselect">
                                            <span data-v-58d387f4="" className="label-content">
                                                <span data-v-58d387f4="">Dapps</span></span>
                                        </div>
                                    </div></a></span>
                        </div>
                    </div> */}
                    </div>
                </div>
            }
        </nav>
    )
}

export default MobileHeader