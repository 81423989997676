import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import giveawayimg from '../../assets/Images/giveawayimg.jpg'
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function GetInvolved() {
    return (

        <section className='getinvolved'>
            <div className="getinvolved-wrap" style={{ display: 'flex' }}>
                <div className="left">


                    <div className="hero_h1-wrapper">


                        <div className="hero_h1-wrapper-top max-width">
                            <h1 className='mint'>Join Deta Finance<br />
                                <span className='whiteimg'> for a Chance to Win $300k! </span>
                            </h1>
                        </div>

                    </div>



                    <div className="hero_paragraph-wrapper">
                        <p >Join our huge Giveaway and win some $ with our Refferel program. Click the link below for more info.</p>
                    </div>



                    <div className="hero_btn-wrapper">
                        <div className="hero_btn-wrapper">


                            <div className="btn_move-over-space">
                                <a href="https://giveaway.deta.io" className="main_btn w-inline-block" >
                                <div className="btn_content main">
                                    <div className="btn_text-wrapper">
                                        <div className="btn_text martopminu2 not-capitalized" >REFERRERS</div>

                                        <div className="btn_text _2 not-capitalized" >REFERRERS</div>
                                    </div>
                                </div>

                                <div className="btn_arrow-wrapper">
                                    <div className="btn_arrow-svg">
                                        <div className="html-embed w-embed"><svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.38709 12.7391L13 1M13 1L13 13M13 1L0.999999 0.999999" stroke="currentColor" stroke-width="1.5"></path>
                                        </svg>
                                        </div>
                                    </div>
                                </div>
                            </a></div>


                        </div>
                    </div>

                </div>

                <div className="right">

                    <div>
                        {/* <div className="programs_grid-item" aria-hidden="true">
                                <a href="/referral-program" className="programs_grid-card w-inline-block" tabindex="-1" aria-hidden="true">
                                    <div className="our_grid-item first" aria-hidden="true">
                                        <img src="/assets/images/ai.png" loading="lazy" alt="" className="our_item-img" aria-hidden="true" />


                                    </div>
                                    <div className="programs_item-heading-wrapper" aria-hidden="true">
                                        <h4 aria-hidden="true">Become a Referrer</h4>
                                    </div>
                                    <div className="partners_item-p-wrapper" aria-hidden="true">
                                        <p aria-hidden="true">
                                            Earn BKNs referring your network
                                            to the upcoming public rounds of BKN.</p>
                                    </div>
                                </a>
                            </div> */}
                        <img src={giveawayimg} alt="" />
                    </div>
                    {/* <div>
                            <div className="programs_grid-item" aria-hidden="true">
                                <a href="/referral-program" className="programs_grid-card w-inline-block" tabindex="-1" aria-hidden="true">
                                    <div className="our_grid-item first" aria-hidden="true">
                                        <img src="/assets/images/ai.png" loading="lazy" alt="" className="our_item-img" aria-hidden="true" />
                                        
                                    
                                    </div>
                                    <div className="programs_item-heading-wrapper" aria-hidden="true">
                                        <h4 aria-hidden="true">Become a Referrer</h4>
                                    </div>
                                    <div className="partners_item-p-wrapper" aria-hidden="true">
                                        <p aria-hidden="true">
                                            Earn BKNs referring your network 
                                            to the upcoming public rounds of BKN.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="programs_grid-item" aria-hidden="true">
                                <a href="/referral-program" className="programs_grid-card w-inline-block" tabindex="-1" aria-hidden="true">
                                    <div className="our_grid-item first" aria-hidden="true">
                                        <img src="/assets/images/ai.png" loading="lazy" alt="" className="our_item-img" aria-hidden="true" />
                                        
                                    
                                    </div>
                                    <div className="programs_item-heading-wrapper" aria-hidden="true">
                                        <h4 aria-hidden="true">Become a Referrer</h4>
                                    </div>
                                    <div className="partners_item-p-wrapper" aria-hidden="true">
                                        <p aria-hidden="true">
                                            Earn BKNs referring your network 
                                            to the upcoming public rounds of BKN.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="programs_grid-item" aria-hidden="true">
                                <a href="/referral-program" className="programs_grid-card w-inline-block" tabindex="-1" aria-hidden="true">
                                    <div className="our_grid-item first" aria-hidden="true">
                                        <img src="/assets/images/ai.png" loading="lazy" alt="" className="our_item-img" aria-hidden="true" />
                                        
                                    
                                    </div>
                                    <div className="programs_item-heading-wrapper" aria-hidden="true">
                                        <h4 aria-hidden="true">Become a Referrer</h4>
                                    </div>
                                    <div className="partners_item-p-wrapper" aria-hidden="true">
                                        <p aria-hidden="true">
                                            Earn BKNs referring your network 
                                            to the upcoming public rounds of BKN.</p>
                                    </div>
                                </a>
                            </div>
                        </div> */}

                </div>
            </div>
        </section>
    )
}

export default GetInvolved