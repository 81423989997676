import React from "react";
import { Box, Button, Dialog, DialogContent, Slide } from "@mui/material";
import { useSwitchNetwork } from "wagmi";
import { withStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledModal = withStyles(() => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "black",
      height: "auto",
      boxShadow: "black 0px 0px 8px 1px",
      //   border: "5px solid black",
      borderRadius: "10px",
    },
    "& .dialoge__content__section": {
      background: "black!important",
      borderRadius: "10px",
      border: "1px solid transparent",
    },
    "& .MuiDialogContent-root": {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
}))(Dialog);
const NetworkDialog = ({ open, setOpen }) => {
  const { switchNetwork } = useSwitchNetwork();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="modal__main__container">
        <StyledModal
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent
            className="dialoge__content__section"
            style={{
              background: "#101507",
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                fontWeight={700}
                component="span"
                color="red"
                fontSize="30px"
              >
                Error!
              </Box>
              <Box
                mt={2}
                fontWeight={400}
                color="#ffffff"
                fontSize="20px"
                textAlign="center"
                lineHeight="30px"
              >
                Please switch your network
              </Box>
            </Box>

            <Button
              sx={{
                color: "#000000",
                background: "#00ffe4",
                fontSize: "18px",
                textTransform: "capitalize",
                fontFamily: "Poppins",
                marginTop: "20px",
                borderRadius: "5px",
                width: "100%",
                "&.Mui-disabled": {
                  color: "#979EA7",
                },
                "&:hover": {
                  background: "#00ffe4",
                },
              }}
              onClick={() => {
                switchNetwork?.(1);
                setOpen(false);
              }}
            >
              Switch Network
            </Button>
          </DialogContent>
        </StyledModal>
      </div>
    </div>
  );
};

export default NetworkDialog;
