import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function SecondSlider() {
  return (
    <div className="SecondSlider">
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={5000}
                transitionDuration={1000}
                arrows={false}
                autoPlay={true}
            >
                <div className='one'>
                    <p>"Deta Finance offers unmatched liquidity aggregation for seamless and efficient trading."</p>
                </div>
                <div className='two'>
                    <p>"Our multi-chain wallet ensures secure storage and management of diverse cryptocurrencies."</p>
                </div>
                <div className='three'>
                    <p>"With AI-powered smart contract deployment, we simplify and optimize blockchain interactions."</p>
                </div>
                <div className='four'>
                    <p> "Deta Finance's Swap 2.0 mechanism revolutionizes token exchanges with user-friendly tax handling."</p>
                </div>
                <div className='five'>
                    <p>"Our perpetual exchange decentralization provides continuous trading opportunities without expiry."</p>
                </div>
                {/* <div className='six'>
                    <p>Our perpetual exchange decentralization provides continuous trading opportunities without expiry.</p>
                </div> */}
            </Carousel>
        </div>
  )
}

export default SecondSlider