import React, { useEffect, useRef, useState } from 'react'
import Marquee from "react-fast-marquee";

function Modekit() {

    return (


        <div className="section is--feat">
            <div className="is--feat">
                <div className="feat">
                    <div className="feat_h2-wrapper">
                        <div className="counter_wrapper">
                        </div><h2 >What The Media Is Saying</h2>
                    </div>
                    <div className="feat_loop-component">
                        <div className="feat_loop-container">
                            <Marquee speed={170}>
                            <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/1.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/2.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/3.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/4.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/5.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/6.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/7.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/8.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                                <a     className="feat_loop-item w-inline-block">
                                    <img src="/assets/images/mediakit/9.png" loading="eager" alt="" className="feat_loop-item-img" />
                                </a>
                            </Marquee>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Modekit