import React,{useState} from 'react'
import chartImg from '../assets/Images/chartImg.png'
import ChartDesktop from '../assets/Images/ChartDesktop.png'
import ChartMobile from '../assets/Images/ChartMobile.png'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


function Tokenomics() {
    const [showDiv, setShowDiv] = useState(false);

    const copyToClipboard = (link) => {
        console.log(link);
        navigator.clipboard.writeText(link);
        setShowDiv(true);
    
        setTimeout(() => {
          setShowDiv(false);
        }, 1000);
      };
    

    return (
    
       
<div className='TokenomicsMainDiv' id="DetaPieChart">

            <div className='TokenomicsInner'>
           
                <div className='Tokenomics1st'>
                    
                    <div className='tokentxt1'>
                        DETA Finance (DETA) Distribution
                    </div>
                    <br />
           
                    <div className='tokentxt2'>
                        Deta Finance is a utility token, The token an ERC20 deployed to the Ethereum Block chain network with the address:
                    </div>
                    {/* <br /> */}

                    {showDiv && (
          <div className={`transition-div ${showDiv ? "show" : ""}`} style={{top:'auto'}}>
            <p>Copy successfully</p>
          </div>
        )}

                    <div className='tokentxt3'>
                       <span onClick={()=>window.open("https://etherscan.io/address/0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5#code","_blank")}>0xfB298521359F8239C2941f93Bd2d</span>
                       <span onClick={()=>window.open("https://etherscan.io/address/0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5#code","_blank")}>40ecfE6a2dc5</span> 
                        <button onClick={() => {
                                      copyToClipboard('0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5');
                                    }}>&nbsp;<ContentCopyIcon /></button>
                    </div>

                    {/* <br /> */}
                    <div className='tokentxt4'>
                        Distribution & Token allocation across the Ecosystem
                    </div>
                    <br />
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            Total Supply
                        </div>
                        <div className='tokentxt6'>
                            800 Million
                        </div>
                    </div>
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            Liquidity
                        </div>
                        <div className='tokentxt6'>
                            12.5% – 100,000,000 DETA tokens
                        </div>
                    </div>
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            Swap 2.0
                        </div>
                        <div className='tokentxt6'>
                            7.5% – 60,000,000 DETA tokens
                        </div>
                    </div>
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            Aggregator
                        </div>
                        <div className='tokentxt6'>
                            7% – 56,000,000 DETA tokens
                        </div>
                    </div>
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            CEX Reserve
                        </div>
                        <div className='tokentxt6'>
                            8% – 64,000,000 DETA tokens
                        </div>
                    </div>
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            Vault
                        </div>
                        <div className='tokentxt6'>
                            5% – 40,000,000 DETA tokens
                        </div>
                    </div>
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            Pools and Farms
                        </div>
                        <div className='tokentxt6'>
                            10% – 80,000,000 DETA tokens
                        </div>
                    </div>
                    <div className='dispFlex'>
                        <div className='tokentxt5'>
                            Presale Tokens
                        </div>
                        <div className='tokentxt6'>
                            50% – 400,000,000 DETA tokens
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column Tokenomics2nd'>
                    <div className='width-img desktopChart'>
                        <img src={ChartDesktop} alt="" />
                    </div>

                    <div className='width-img mobileChart'>
                        <img src={ChartMobile} alt="" />
                    </div>
                    <img src="/assets/images/TokenBadge.svg" onClick={()=>window.open("https://www.team.finance/view-coin/0xfB298521359F8239C2941f93Bd2d40ecfE6a2dc5?name=Deta","_blank")}  alt="" style={{ margin: "30px",marginTop:'70px', marginInline: 'auto' }} />
                              
                </div>
            </div>
        </div>
    )
}

export default Tokenomics