import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  InputBase,
  Slide,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CSVLink } from "react-csv";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import { formatUnits, parseUnits } from "viem";
import { AppContext } from "../utils";
import { useNetwork } from "wagmi";
import {
  StyledButton,
  StyledCurrencyButton,
  StyledText,
} from "../Components/SmallComponents/AppComponents";
import { fetchBalance } from "@wagmi/core";
import { useWeb3Modal } from "@web3modal/react";
import {
  bscPresaleReadFunction,
  bscUsdtReadFunction,
  ethPresaleReadFunction,
  ethUsdtReadFunction,
  bscPresaleWriteFunction,
  bscUsdtWriteFunction,
  ethPresaleWriteFunction,
  ethUsdtWriteFunction,
} from "../ConnectivityAssets/newHook";
import {
  ethPresaleAddress,
  bscPresaleAddress,
} from "../ConnectivityAssets/environment";
import { ToastNotify } from "../ConnectivityAssets/newHook";
import { admin } from "../ConnectivityAssets/environment";
import { bnb, deta, eth, usdt } from "../Components/SmallComponents/Images";

const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    height: "40px",
    fontSize: "15px",
    fontFamily: "Poppins",
    fontWeight: "600",
    textAlign: "left",
    color: "gray",
    backgroundColor: "transparent",
    paddingLeft: "15px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Presale = () => {
  const { chain } = useNetwork();
  const { open } = useWeb3Modal();
  const matches = useMediaQuery("(max-width:700px)");
  const { account, connectedChainId } = useContext(AppContext);
  const [web3ChainId, setweb3ChainId] = useState(0);
  const [token, setToken] = useState("USDTETH");
  const [openDialog, setOpenDialog] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [balanceUSDT, setbalanceUSDT] = useState(0);
  const [balanceETH, setbalanceETH] = useState(0);
  const [balanceBNB, setbalanceBNB] = useState(0);
  const [balanceUSDTBNB, setbalanceUSDTBNB] = useState(0);
  const [receivedTokens, setreceivedTokens] = useState("");
  const [presaleStatus, setpresaleStatus] = useState(false);
  const [presaleStatusBSC, setpresaleStatusBSC] = useState(false);
  const [raisedAmount, setRaisedAmount] = useState(0);
  const [raisedAmountForStage, setRaisedAmountForStage] = useState(0);
  const [progressBar, setProgessBar] = useState(0);
  const [loading, setloading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [totalSupplyPerPhase, settotalSupplyPerPhase] = useState(0);
  const [hardCapPerPhase, sethardCapPerPhase] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [userPurchasedTokensBSC, setuserPurchasedTokensBSC] = useState(0);
  const [soldTokens, setsoldTokens] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [csvAmounts, setcsvAmounts] = useState([]);
  const [csvAddresses, setcsvAddresses] = useState([]);
  const [nextStagePrice, setnextStagePrice] = useState(0);
  const [presaleStage, setpresaleStage] = useState(0);
  const [oneUSDTtoToken, setoneUSDTtoToken] = useState(0);
  const [oneETHtoToken, setoneETHtoToken] = useState(0);
  const [oneBNBtoToken, setoneBNBtoToken] = useState(0);
  const [loadingData, setloadingData] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    (() => {
      setweb3ChainId(chain?.id);
      if (+chain?.Id === 56) {
        setToken("USDTBNB");
      }
    })();
  }, [connectedChainId, account, chain]);
  // VoidSigner Valuse get from contracts
  const initVoidSigner = async () => {
    try {
      setloadingData(true);
      const saleStage = await ethPresaleReadFunction("StageCount");
      setpresaleStage(+saleStage.toString());
      const rec1 = await ethPresaleReadFunction("usdtToToken", ["1000000"]);
      setoneUSDTtoToken(+formatUnits(rec1.toString(), 18));
      const rec2 = await ethPresaleReadFunction("NativeToToken", [
        "1000000000000000000",
      ]);
      setoneETHtoToken(+formatUnits(rec2.toString(), 18));
      const rec3 = await bscPresaleReadFunction("NativeToToken", [
        "1000000000000000000",
      ]);
      setoneBNBtoToken(+formatUnits(rec3.toString(), 18));
      let perPhaseSupplyETH = await ethPresaleReadFunction("tokenForSell");
      let perPhaseSupply = +formatUnits(perPhaseSupplyETH.toString(), 18);
      perPhaseSupply = parseFloat(perPhaseSupply).toFixed(0);
      settotalSupplyPerPhase(toLocalFormat(perPhaseSupply));
      let price = await ethPresaleReadFunction("tokenPerUsd");
      settokenPrice(+formatUnits(price.toString(), 18));
      price = +formatUnits(price.toString(), 18);
      let hardCap = +perPhaseSupply / +price;
      hardCap = parseFloat(hardCap).toFixed(0);
      sethardCapPerPhase(toLocalFormat(hardCap));
      const nsp = await ethPresaleReadFunction("NextStagePrice");
      setnextStagePrice(+formatUnits(nsp.toString(), 18));
      const soldTokensBNB = await bscPresaleReadFunction("ResetTokenForstage");
      const soldTokensETH = await ethPresaleReadFunction("ResetTokenForstage");
      let totalSold =
        +formatUnits(soldTokensBNB.toString(), 18) +
        +formatUnits(soldTokensETH.toString(), 18);
      totalSold = parseFloat(totalSold).toFixed(0);
      setsoldTokens(toLocalFormat(totalSold));
      const supplyTotal = await ethPresaleReadFunction("totalSupply");
      const soldTotalETH = await ethPresaleReadFunction("soldToken");
      const soldTotalBNB = await bscPresaleReadFunction("soldToken");
      let total =
        +formatUnits(soldTotalETH.toString(), 18) +
        +formatUnits(soldTotalBNB.toString(), 18);
      let prog = (+total / +formatUnits(supplyTotal.toString(), 18)) * 100;
      setProgessBar(+prog);
      /////////// Raised Total
      const raised = await bscPresaleReadFunction("amountRaised");
      let usdtRaisedBSC = await bscPresaleReadFunction("amountRaisedUSDT");
      usdtRaisedBSC = +formatUnits(usdtRaisedBSC.toString(), 18);
      const currentPriceBNB = await bscPresaleReadFunction("getLatestPrice");
      let bnbToUsdt =
        +formatUnits(raised.toString(), 18) *
        +formatUnits(currentPriceBNB.toString(), 8);
      const ethRaised = await ethPresaleReadFunction("amountRaised");
      let usdtRaised = await ethPresaleReadFunction("amountRaisedUSDT");
      usdtRaised = +formatUnits(usdtRaised.toString(), 6);
      const ethPrice = await ethPresaleReadFunction("getLatestPrice");
      let ethToUsdt =
        +formatUnits(ethRaised.toString(), 18) *
        +formatUnits(ethPrice.toString(), 8);
      let totalRaised = +ethToUsdt + +usdtRaised + +bnbToUsdt + +usdtRaisedBSC;
      totalRaised = parseFloat(totalRaised).toFixed(0);
      setRaisedAmount(toLocalFormat(totalRaised));
      /////////// Raised For Stage
      const bnbraisedFS = await bscPresaleReadFunction("amountRaisedForStage");
      let usdtRaisedFSBSC = await bscPresaleReadFunction(
        "amountRaisedUSDTForStage"
      );
      usdtRaisedFSBSC = +formatUnits(usdtRaisedFSBSC.toString(), 18);
      let bnbToUsdtFS =
        +formatUnits(bnbraisedFS.toString(), 18) *
        +formatUnits(currentPriceBNB.toString(), 8);
      const ethRaisedFS = await ethPresaleReadFunction("amountRaisedForStage");
      let usdtRaisedFS = await ethPresaleReadFunction(
        "amountRaisedUSDTForStage"
      );
      usdtRaisedFS = +formatUnits(usdtRaisedFS.toString(), 6);
      let ethToUsdtFS =
        +formatUnits(ethRaisedFS.toString(), 18) *
        +formatUnits(ethPrice.toString(), 8);
      let totalRaisedFS =
        +usdtRaisedFS + +ethToUsdtFS + +bnbToUsdtFS + +usdtRaisedFSBSC;
      totalRaisedFS = parseFloat(totalRaisedFS).toFixed(0);
      setRaisedAmountForStage(toLocalFormat(totalRaisedFS));
      setloadingData(false);
      const isFinished = await ethPresaleReadFunction("presaleStatus");
      const isFinishedBSC = await bscPresaleReadFunction("presaleStatus");
      setpresaleStatus(isFinished);
      setpresaleStatusBSC(isFinishedBSC);
    } catch (error) {
      console.log(error, "ERROR VoidSigner Data");
    }
  };

  const initUser = async () => {
    try {
      if (+connectedChainId === 56) {
        const bnbBal = await fetchBalance({
          address: account,
        });
        let balBNB = +formatUnits(bnbBal?.value?.toString(), 18);
        setbalanceBNB(balBNB);
        const balance = await bscUsdtReadFunction("balanceOf", [account]);
        let usdtBal = parseFloat(+formatUnits(balance.toString(), 18)).toFixed(
          2
        );
        setbalanceUSDTBNB(toLocalFormat(usdtBal));
        const user = await bscPresaleReadFunction("users", [account]);
        setuserPurchasedTokens(
          toLocalFormat(+formatUnits(user[2].toString(), 18))
        );
      }
      if (+connectedChainId === 1) {
        const walletBalance = await await fetchBalance({
          address: account,
        });
        let ethBal = +formatUnits(walletBalance?.value?.toString(), 18);
        setbalanceETH(ethBal);
        const balance = await ethUsdtReadFunction("balanceOf", [account]);
        let usdtBal = parseFloat(+formatUnits(balance.toString(), 6)).toFixed(
          2
        );
        setbalanceUSDT(toLocalFormat(usdtBal));
        const user = await ethPresaleReadFunction("users", [account]);
        let userTokens = parseFloat(
          +formatUnits(user[2].toString(), 18)
        ).toFixed(2);
        setuserPurchasedTokens(toLocalFormat(userTokens));
        const purchasedWL = await ethPresaleReadFunction("wallets", [account]);
        setuserPurchasedTokensBSC(+formatUnits(purchasedWL.toString(), 18));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (account) {
      initUser();
    }
  }, [account, connectedChainId]);
  useEffect(() => {
    initVoidSigner();
  }, []);

  const buyHadler = async () => {
    if (account) {
      if (+connectedChainId !== 1 && +connectedChainId !== 56) {
        setAlertState({
          open: true,
          message: `Error! Please switch your network to ethereum or binance`,
          severity: "error",
        });
      } else if (!presaleStatus || !presaleStatusBSC) {
        setAlertState({
          open: true,
          message: `Error! Presale not started yet`,
          severity: "error",
        });
      } else if (+soldTokens >= +totalSupplyPerPhase) {
        setAlertState({
          open: true,
          message: `Error! Please wait for the next stage this stage is ended`,
          severity: "error",
        });
      } else if (!enteredAmount) {
        setAlertState({
          open: true,
          message: `Error! Please enter a amount`,
          severity: "error",
        });
      } else if (enteredAmount <= 0) {
        setAlertState({
          open: true,
          message: `Error! Please enter a valid amount`,
          severity: "error",
        });
      } else {
        try {
          setOpenDialog(true);
          setloading(true);
          if (+connectedChainId === 1) {
            if (token === "USDTETH") {
              let balanceOf = await ethUsdtReadFunction("balanceOf", [account]);
              let allowance = await ethUsdtWriteFunction("allowance", [
                account,
                ethPresaleAddress,
              ]);
              allowance = +formatUnits(allowance.toString(), 6);
              if (+allowance < +enteredAmount) {
                await ethUsdtReadFunction("approve", [
                  ethPresaleAddress,
                  balanceOf.toString(),
                ]);
              }
              await ethPresaleWriteFunction("buyTokenUSDT", [
                parseUnits(enteredAmount.toString(), 6),
              ]);
            } else {
              await ethPresaleWriteFunction(
                "buyToken",
                [],
                parseUnits(enteredAmount.toString(), 18).toString()
              );
            }
          } else {
            if (token === "USDTBNB") {
              let balanceOf = await bscUsdtReadFunction("balanceOf", [account]);
              let allowance = await bscUsdtWriteFunction("allowance", [
                account,
                bscPresaleAddress,
              ]);
              allowance = +formatUnits(allowance.toString(), 18);
              if (+allowance < +enteredAmount) {
                await bscUsdtWriteFunction("approve", [
                  bscPresaleAddress,
                  balanceOf.toString(),
                ]);
              }
              await bscPresaleWriteFunction("buyTokenUSDT", [
                parseUnits(enteredAmount.toString(), 18),
              ]);
            } else {
              await bscPresaleWriteFunction(
                "buyToken",
                [],
                parseUnits(enteredAmount.toString(), 18).toString()
              );
            }
          }
          setEnteredAmount("");
          initUser();
          initVoidSigner();
          setloading(false);
          setComplete(true);
        } catch (error) {
          setOpenDialog(false);
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const calculator = async () => {
      try {
        if (token === "USDTETH" || token === "USDTBNB") {
          let tokenUSDT = +oneUSDTtoToken * +enteredAmount;
          setreceivedTokens(tokenUSDT);
        } else if (token === "ETH") {
          let tokenEth = +oneETHtoToken * +enteredAmount;
          setreceivedTokens(tokenEth);
        } else {
          let tokenbnb = +oneBNBtoToken * +enteredAmount;
          setreceivedTokens(tokenbnb);
        }
      } catch (error) {}
    };
    if (+enteredAmount > 0) {
      calculator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredAmount, account, token]);

  const claimHandler = async () => {
    if (account) {
      if (presaleStatus) {
        setAlertState({
          open: true,
          message: `You can claim tokens after presale will end.`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          if (+userPurchasedTokens > 0) {
            await ethPresaleWriteFunction("claimTokens");
          }
          if (+userPurchasedTokensBSC > 0) {
            await ethPresaleWriteFunction("ClaimForBSC");
          }
          setloading(false);
          setComplete(true);
          setAlertState({
            open: true,
            message: `Transection Completed!`,
            severity: "success",
          });
          setEnteredAmount("");
          initVoidSigner();
        } catch (error) {
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };

  const csvInit = async () => {
    try {
      if (+connectedChainId === 56 || +web3ChainId === 56) {
        const totalUser = await bscPresaleReadFunction("totalUsers");
        let csvAddressesArray = [];
        let csvAmountsArray = [];
        for (let i = 0; i < +totalUser.toString(); i++) {
          const addresses = await bscPresaleReadFunction("UsersAddresses", [
            i.toString(),
          ]);
          csvAddressesArray.push(addresses.toString());
          const user = await bscPresaleReadFunction("users", [addresses]);
          let userPurchasedTokens = +formatUnits(user[2].toString(), 18);
          userPurchasedTokens = parseFloat(userPurchasedTokens).toFixed(0);
          csvAmountsArray.push(userPurchasedTokens.toString());
        }
        setcsvAddresses([csvAddressesArray]);
        setcsvAmounts([csvAmountsArray]);
      }
    } catch (error) {
      console.log(error, "CSV ERROR");
    }
  };
  useEffect(() => {
    csvInit();
  }, [connectedChainId, web3ChainId]);
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        sx={{
          zIndex: 5,
          "& .MuiPaper-root": {
            background: "#ffffff",
            width: matches ? "100%" : "30%",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            height: "100%",
          }}
        >
          <Box
            py={1}
            pl={3}
            pr={1}
            sx={{
              backgroundImage:
                "linear-gradient(180deg,#4ACFFF -4.32%,#00ffe4 91.56%)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledText>Processing</StyledText>
            {complete && (
              <CloseIcon
                onClick={() => {
                  setEnteredAmount("");
                  setOpenDialog(!openDialog);
                  setComplete(false);
                }}
                style={{
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              />
            )}
          </Box>
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              px={matches ? 1 : 2}
              py={matches ? 2 : 3}
            >
              <video
                autoPlay
                crossOrigin="anonymous"
                loop
                muted
                playsInline
                preload="auto"
                role="presentation"
                tabIndex="-1"
                style={{
                  borderRadius: "10px",
                  height: "100%",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <source src="/video.mp4" />
              </video>
            </Box>
          ) : complete ? (
            <Box p={2} textAlign="center" color="gray">
              <CheckCircleIcon sx={{ color: "#57CA5C", fontSize: "75px" }} />
              <Typography variant="body1" fontWeight="600">
                YOUR PURCHASE WAS SUCCESSFUL!
              </Typography>
              <Typography
                my={2}
                px={2}
                variant="subtitle2"
                fontSize="13px"
                fontWeight="600"
                color="gray"
              >
                Token will be available for you to claim once the presale ends.
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Dialog>

      <Box
        sx={{
          background: "linear-gradient(#1e1e1e -4.32%,#0d0d0d 91.56%)",
          border: "5px solid #ffffff",
          backdropFilter: "blur(9px)",
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Box p={3}>
          <Box display="flex" justifyContent="center" width="100%">
            <Box
              sx={{
                width: "200px",
                borderRadius: "12px",
                py: 1,
                mb: 1,
                // marginTop:"50px"
              }}
              className="glow"
            >
              PRESALE {loadingData ? 1 : presaleStage}
            </Box>
          </Box>
          {/* Progress bar */}

          <div className="meter">
            <span style={{ width: `${loadingData ? 0 : progressBar}%` }}></span>
            <Box
              sx={{
                position: "absolute",
                top: "10%",
                left: "43%",
                color: "#ffffff",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              {loadingData ? "0.000" : parseFloat(progressBar).toFixed(3)}%
            </Box>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#ffffff",
                }}
              >
                Current Stage Price
              </Box>

              <Box
                mt={1}
                bgcolor="#00ffe4"
                borderRadius="7px"
                fontSize="18px"
                color="#000000"
                p={1}
                // width="100%"
              >
                1 $DETA = ${" "}
                {+tokenPrice > 0
                  ? parseFloat(1 / +tokenPrice).toFixed(3)
                  : "0.012"}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#ffffff",
                }}
              >
                Next Stage Price
              </Box>

              <Box
                mt={1}
                bgcolor="red"
                borderRadius="7px"
                fontSize="18px"
                color="#ffffff"
                p={1}
                // width="100%"
              >
                1 $DETA = $
                {+nextStagePrice > 0
                  ? parseFloat(1 / +nextStagePrice).toFixed(3)
                  : "0.020"}
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                color: "#ffffff",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Total Raised
            </Box>

            <Box
              sx={{
                color: "#ffffff",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              ${loadingData ? 0 : raisedAmount}
            </Box>
          </Box>

          <Box display="flex" alignItems="center" flexDirection="column">
            <StyledText>
              SOLD - {loadingData ? 0 : soldTokens}
              <span style={{ fontSize: "20px" }}>/</span>
              {loadingData ? 0 : totalSupplyPerPhase}
            </StyledText>
            <StyledText>
              USDT RAISED - ${loadingData ? 0 : raisedAmountForStage}
              <span style={{ fontSize: "20px" }}>/</span>$
              {loadingData ? 0 : hardCapPerPhase}
            </StyledText>
          </Box>
          <Box mt={2} />

          <Box mt={2} />
          {+connectedChainId === 1 || +web3ChainId === 1 ? (
            <Grid container spacing={3}>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton
                  width="100%"
                  cursor="pointer"
                  color={token === "ETH" ? "#00ffe4" : "#212529"}
                  border={
                    token === "ETH" ? "2px solid #00ffe4" : "2px solid #F1F4F6"
                  }
                  onClick={() => setToken("ETH")}
                >
                  ETH
                </StyledCurrencyButton>
              </Grid>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton
                  width="100%"
                  cursor="pointer"
                  color={token === "USDTETH" ? "#00ffe4" : "#212529"}
                  border={
                    token === "USDTETH"
                      ? "2px solid #00ffe4"
                      : "2px solid #F1F4F6"
                  }
                  onClick={() => setToken("USDTETH")}
                >
                  <Box lineHeight="15px">
                    USDT <br />
                    <span
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Erc20
                    </span>
                  </Box>
                </StyledCurrencyButton>
              </Grid>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton cursor="no-drop" width="100%">
                  BNB
                </StyledCurrencyButton>
              </Grid>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton cursor="no-drop" width="100%">
                  <Box lineHeight="15px">
                    USDT <br />
                    <span
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Bep20
                    </span>
                  </Box>
                </StyledCurrencyButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton cursor="no-drop" width="100%">
                  ETH
                </StyledCurrencyButton>
              </Grid>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton cursor="no-drop" width="100%">
                  <Box lineHeight="15px">
                    USDT <br />
                    <span
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Erc20
                    </span>
                  </Box>
                </StyledCurrencyButton>
              </Grid>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton
                  width="100%"
                  cursor="pointer"
                  color={token === "BNB" ? "#00ffe4" : "#212529"}
                  border={
                    token === "BNB" ? "2px solid #00ffe4" : "2px solid #F1F4F6"
                  }
                  onClick={() => {
                    setToken("BNB");
                  }}
                >
                  BNB
                </StyledCurrencyButton>
              </Grid>
              <Grid item xs={6} md={3}>
                <StyledCurrencyButton
                  width="100%"
                  cursor="pointer"
                  color={token === "USDTBNB" ? "#00ffe4" : "#212529"}
                  border={
                    token === "USDTBNB"
                      ? "2px solid #00ffe4"
                      : "2px solid #F1F4F6"
                  }
                  onClick={() => {
                    setToken("USDTBNB");
                  }}
                >
                  <Box lineHeight="15px">
                    USDT <br />
                    <span
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      Bep20
                    </span>
                  </Box>
                </StyledCurrencyButton>
              </Grid>
            </Grid>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              textAlign="center"
              mb={0.5}
              fontWeight="500"
              color="gray"
              mt={2}
              width="47%"
            >
              {token === "ETH" ? "ETH" : token === "BNB" ? "BNB" : "USDT"}{" "}
              Balance <br />{" "}
              {token === "ETH"
                ? parseFloat(balanceETH).toFixed(4)
                : token === "BNB"
                ? parseFloat(balanceBNB).toFixed(4)
                : token === "USDTETH"
                ? balanceUSDT
                : balanceUSDTBNB}
            </Box>
            <Box
              textAlign="center"
              mb={0.5}
              fontWeight="500"
              color="gray"
              mt={2}
              width="47%"
            >
              $DETA Balance <br /> {userPurchasedTokens}
            </Box>
          </Box>
          <Divider
            style={{
              background: "lightGray",
              height: "1px",
              marginBottom: "20px",
            }}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={matches ? "column" : "row"}
            width="100%"
            mb={3}
          >
            <Box
              width={matches ? "100%" : "47%"}
              textAlign="left"
              fontWeight={600}
              color="#ffffff"
            >
              {token === "ETH" ? "ETH" : token === "BNB" ? "BNB" : "USDT"}{" "}
              <span style={{ fontWeight: 400, color: "gray" }}>
                you will pay
              </span>
              <Box
                sx={{
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "#F1F4F6",
                }}
              >
                <TextInput
                  fullWidth
                  placeholder="Enter amount"
                  type="number"
                  value={enteredAmount}
                  onChange={(e) => setEnteredAmount(e.target.value)}
                />
                <Box pr={1} display="flex" alignItems="center">
                  <img
                    src={
                      (web3ChainId === 1 || connectedChainId === 1) &&
                      token === "ETH"
                        ? eth
                        : (web3ChainId === 56 || connectedChainId === 56) &&
                          token === "BNB"
                        ? bnb
                        : usdt
                    }
                    alt=""
                    style={{
                      marginRight: "4px",
                      width: "30px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              width={matches ? "100%" : "47%"}
              textAlign="left"
              fontWeight={600}
              mt={matches ? 1 : 0}
              color="#ffffff"
            >
              $DETA{" "}
              <span style={{ fontWeight: 400, color: "gray" }}>
                you will get
              </span>
              <Box
                sx={{
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: "#F1F4F6",
                }}
              >
                <TextInput
                  fullWidth
                  placeholder="0"
                  type="number"
                  value={
                    +enteredAmount > 0
                      ? parseFloat(receivedTokens).toFixed(2)
                      : ""
                  }
                />
                <Box pr={1} display="flex" alignItems="center">
                  <img
                    src={deta}
                    alt=""
                    style={{
                      marginRight: "4px",
                      width: "30px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {account ? (
            <StyledButton
              colortext="#000000"
              width="100%"
              onClick={() => buyHadler()}
            >
              Buy Now
            </StyledButton>
          ) : (
            <StyledButton
              colortext="#000000"
              width="100%"
              onClick={async () => open()}
            >
              Connect Wallet
            </StyledButton>
          )}
          <Box mt={1} />
          {account && (
            <Button
              sx={{
                color: "#ffffff",
                background: "transparent",
                fontSize: "18px",
                textTransform: "capitalize",
                fontFamily: "Poppins",
                borderRadius: "50px",
                width: "100%",
                border: "2px solid #00ffe4",
                "&:hover": {
                  background: "transparent",
                },
              }}
              onClick={async () => open()}
            >
              {account.slice(0, 5) + "..." + account.slice(-5)}
            </Button>
          )}
          {admin.toLowerCase() === account?.toLowerCase() && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mt={1}
            >
              <Box
                display="flex"
                alignItems="center "
                justifyContent="center"
                width={matches ? "100%" : "49%"}
              >
                <CSVLink
                  style={{
                    color: "#000000",
                    background: "#00ffe4",
                    fontSize: "18px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                    borderRadius: "50px",
                    padding: "10px 20px",
                    textDecoration: "none",
                    width: "100%",
                  }}
                  filename={"Addresses.csv"}
                  data={csvAddresses}
                >
                  Addresses CSV
                </CSVLink>
              </Box>
              <Box
                display="flex"
                alignItems="center "
                justifyContent="center"
                width={matches ? "100%" : "49%"}
                mt={matches ? 2 : 0}
              >
                <CSVLink
                  style={{
                    color: "#000000",
                    background: "#00ffe4",
                    fontSize: "18px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                    borderRadius: "50px",
                    padding: "10px 20px",
                    textDecoration: "none",
                    width: "100%",
                  }}
                  data={csvAmounts}
                  filename={"Amounts.csv"}
                >
                  Amounts CSV
                </CSVLink>
              </Box>
            </Box>
          )}
          {+userPurchasedTokens.length > 4 && +connectedChainId === 1 ? (
            <Box mt={1}>
              <StyledButton
                onClick={() => claimHandler()}
                width="100%"
                colortext="#ffffff"
              >
                Claim DETA Tokens
              </StyledButton>

              <StyledText>
                You can claim tokens after the presale will end
              </StyledText>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default Presale;
